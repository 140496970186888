/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { addTodo } from "../shared/redux/actions/postAction";
import { setUser, fetchUserData } from "../shared/redux/actions/userActions";
import {
  setEvents,
  fetchEventsData,
  setEventsQuery,
  initCiData,
} from "../shared/redux/actions/eventsActions";
import { fetchPriceData } from "../shared/redux/actions/priceActions";
import { connect } from "react-redux";

import environment from "../config";
var ps;

// @ts-ignore
const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const uid = localStorage.getItem("uid");

  if (!props.user && uid) props.fetchUserData(uid);
  const getTimeFilter = () => {
    const fullWeek = 1000 * 60 * 60 * 24 * 6;
    let endDate = new Date();
    let startDate = new Date(new Date().getTime() - fullWeek);
    startDate.setHours(0);
    startDate.setMinutes(0);

    return { startDate: startDate.getTime(), endDate: endDate.getTime() };
  };

  const userDevices = props?.user?.userDevices;

  const initEventsData = (queryData = null) => {
    props.fetchPriceData(queryData.deviceSnn);
    props.fetchEventsData(queryData);
  };

  if (userDevices && userDevices.length > 0 && !props.events) {
    const time = getTimeFilter();
    const queryData = props.eventsQueryData
      ? props.eventsQueryData
      : {
          deviceSnn: userDevices?.[0].serial,
          startTs: time.startDate,
          endTs: time.endDate,
        };
    props.initCiData();
    if (queryData.deviceSnn && queryData.deviceSnn.includes("GEYSER"))
      initEventsData(queryData);
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState();

  const [color, setColor] = React.useState("orange");
  const [bgColor, setBgColor] = React.useState("white");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/SensorConnect-Logo.svg")
  );
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(logo);
        break;
      default:
        setLogo(logo);
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname === "/admin/dashboard";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    if (!miniActive) {
      setTimeout(() => {
        setLogo(require("assets/img/sensor-connect-logo-icon.png"));
      }, 1200);
    } else {
      setLogo(require("assets/img/SensorConnect-Logo.svg"));
    }
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={""}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.map}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    events: state.eventsReducer.events,
    eventsQueryData: state.eventsReducer.queryData,
  };
};

export default connect(mapStateToProps, {
  addTodo,
  setUser,
  fetchUserData,
  fetchEventsData,
  fetchPriceData,
  setEventsQuery,
  setEvents,
  initCiData,
})(Dashboard);
