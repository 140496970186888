import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import OneCharts from "./views/Charts/OneChart.js";
import GeyserPage from "./views/Charts/GeyserPage.js";
import IPCam from "./views/Charts/IPCam.js";
import Dashboard from "views/Dashboard/Dash.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import DeviceHub from "@material-ui/icons/DeviceHub";
import PieChart from "@material-ui/icons/PieChart";
import LinearScaleSharp from "@material-ui/icons/LinearScaleSharp";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import AvTimer from "@material-ui/icons/AvTimer";
import BubbleChart from "@material-ui/icons/BubbleChart";
import WidgetsIcon from "@material-ui/icons/Widgets";
import {
  internalTempChart,
  ambientTempChart,
  externalTempChart,
  heatingTimeChart,
  energyDataChart,
  dailyEnergyUsedPieChart,
} from "variables/deviceCharts";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Sensors",
    rtlName: "صفحات",
    icon: Image,
    state: "chartsCollapse",
    views: [
      {
        path: "/device",
        name: "My Device",
        rtlName: "الرسوم البيانية",
        icon: DeviceHub,
        component: GeyserPage,
        layout: "/admin",
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/IPCam",
        name: "IPCam",
        rtlName: "الرسوم البيانية",
        icon: DeviceHub,
        component: IPCam,
        layout: "/admin",
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin",
      },
    ],
  },
];
// eslint-disable-next-line no-constant-condition
if (1 === 2) {
  dashRoutes.push({
    collapse: true,
    name: "Helpers",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/old-dash",
        name: "Old Dash",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Pages",
        rtlName: "صفحات",
        icon: Image,
        state: "pageCollapse",
        views: [
          {
            path: "/user-page",
            name: "User Profile",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "UP",
            rtlMini: "شع",
            component: UserProfile,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Components",
        rtlName: "المكونات",
        icon: Apps,
        state: "componentsCollapse",
        views: [
          {
            collapse: true,
            name: "Multi Level Collapse",
            rtlName: "انهيار متعدد المستويات",
            mini: "MC",
            rtlMini: "ر",
            state: "multiCollapse",
            views: [
              {
                path: "/buttons",
                name: "Buttons",
                rtlName: "وصفت",
                mini: "B",
                rtlMini: "ب",
                component: Buttons,
                layout: "/admin",
              },
            ],
          },
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin",
          },
          {
            path: "/grid-system",
            name: "Grid System",
            rtlName: "نظام الشبكة",
            mini: "GS",
            rtlMini: "زو",
            component: GridSystem,
            layout: "/admin",
          },
          {
            path: "/panels",
            name: "Panels",
            rtlName: "لوحات",
            mini: "P",
            rtlMini: "ع",
            component: Panels,
            layout: "/admin",
          },
          {
            path: "/sweet-alert",
            name: "Sweet Alert",
            rtlName: "الحلو تنبيه",
            mini: "SA",
            rtlMini: "ومن",
            component: SweetAlert,
            layout: "/admin",
          },
          {
            path: "/notifications",
            name: "Notifications",
            rtlName: "إخطارات",
            mini: "N",
            rtlMini: "ن",
            component: Notifications,
            layout: "/admin",
          },
          {
            path: "/icons",
            name: "Icons",
            rtlName: "الرموز",
            mini: "I",
            rtlMini: "و",
            component: Icons,
            layout: "/admin",
          },
          {
            path: "/typography",
            name: "Typography",
            rtlName: "طباعة",
            mini: "T",
            rtlMini: "ر",
            component: Typography,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Forms",
        rtlName: "إستمارات",
        icon: "content_paste",
        state: "formsCollapse",
        views: [
          {
            path: "/regular-forms",
            name: "Regular Forms",
            rtlName: "أشكال عادية",
            mini: "RF",
            rtlMini: "صو",
            component: RegularForms,
            layout: "/admin",
          },
          {
            path: "/extended-forms",
            name: "Extended Forms",
            rtlName: "نماذج موسعة",
            mini: "EF",
            rtlMini: "هوو",
            component: ExtendedForms,
            layout: "/admin",
          },
          {
            path: "/validation-forms",
            name: "Validation Forms",
            rtlName: "نماذج التحقق من الصحة",
            mini: "VF",
            rtlMini: "تو",
            component: ValidationForms,
            layout: "/admin",
          },
          {
            path: "/wizard",
            name: "Wizard",
            rtlName: "ساحر",
            mini: "W",
            rtlMini: "ث",
            component: Wizard,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Tables",
        rtlName: "الجداول",
        icon: GridOn,
        state: "tablesCollapse",
        views: [
          {
            path: "/regular-tables",
            name: "Regular Tables",
            rtlName: "طاولات عادية",
            mini: "RT",
            rtlMini: "صر",
            component: RegularTables,
            layout: "/admin",
          },
          {
            path: "/extended-tables",
            name: "Extended Tables",
            rtlName: "جداول ممتدة",
            mini: "ET",
            rtlMini: "هور",
            component: ExtendedTables,
            layout: "/admin",
          },
          {
            path: "/react-tables",
            name: "React Tables",
            rtlName: "رد فعل الطاولة",
            mini: "RT",
            rtlMini: "در",
            component: ReactTables,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Maps",
        rtlName: "خرائط",
        icon: Place,
        state: "mapsCollapse",
        views: [
          {
            path: "/google-maps",
            name: "Google Maps",
            rtlName: "خرائط جوجل",
            mini: "GM",
            rtlMini: "زم",
            component: GoogleMaps,
            layout: "/admin",
          },
          {
            path: "/full-screen-maps",
            name: "Full Screen Map",
            rtlName: "خريطة كاملة الشاشة",
            mini: "FSM",
            rtlMini: "ووم",
            component: FullScreenMap,
            layout: "/admin",
          },
          {
            path: "/vector-maps",
            name: "Vector Map",
            rtlName: "خريطة المتجه",
            mini: "VM",
            rtlMini: "تم",
            component: VectorMap,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/widgets",
        name: "Widgets",
        rtlName: "الحاجيات",
        icon: WidgetsIcon,
        component: Widgets,
        layout: "/admin",
      },
      {
        path: "/charts",
        name: "Charts",
        rtlName: "الرسوم البيانية",
        icon: Timeline,
        component: Charts,
        layout: "/admin",
      },
      {
        path: "/calendar",
        name: "Calendar",
        rtlName: "التقويم",
        icon: DateRange,
        component: Calendar,
        layout: "/admin",
      },
    ],
  });
}
export default dashRoutes;
