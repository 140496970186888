import gql from "graphql-tag";

export const AccountFragment = gql`
  fragment AccountQL on AccountData {
    snn
    tags
    status
    created_ts
    verified_ts
    firstName
    lastName
    username
    emailAddress
    mobileNo
    meta
    firebaseUid
    isMobileNoVerified
    roles
    groups
    provider
    ownedActiveDevicesCollection {
      esItems {
        serial
        active {
          serviceStub {
            name
          }
        }
      }
    }
    friendsActiveDevicesCollection {
      esItems {
        serial
        active {
          serviceStub {
            name
          }
        }
      }
    }
  }
`;

export const ACCOUNT_QUERY = gql`
  query getAccount($snn: String!) {
    account(snn: $snn) {
      ...AccountQL
    }
    queries {
      mobileFrontPageServices(
        filter: { state: { _nin: ["closed"] } }
        sort: { field: "type" }
      ) {
        service {
          name
        }
        device {
          serial
        }
      }
    }
  }
  ${AccountFragment}
`;
