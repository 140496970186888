import environment from "../../../config";
const axios = require("axios").default;

export function fetchPriceData(queryData) {
  return (dispatch) => {
    getDefaultKwPrice(dispatch, queryData);
  };
}
export async function getDefaultKwPrice(dispatch) {
  const api = `${environment.api}/geyser-adapter/kilowatt-hour-prices`;
  let response = await axios.get(api);

  if (response.status !== 200) {
    console.log("Error:", response);
  } else {
    dispatch(setPrice(response.data.prices));
  }
}
export async function getDeviceKwPrice(dispatch, deviceSnn) {
  const api = `${environment.api}/geyser-adapter/geyser/${deviceSnn}/kilowatt-hour-prices`;
  let response = await axios.get(api);

  if (response.status !== 200) {
    console.log("Error:", response);
  } else {
    dispatch(setPrice(response.data.prices));
  }
}
export async function setDeviceKwPrice(dispatch, deviceSnn, payload) {
  const api = `${environment.api}/geyser-adapter/geyser/${deviceSnn}/kilowatt-hour-prices`;
  let response = await axios.post(api, { prices: payload });

  if (response.status !== 200) {
    console.log("Error:", response);
  } else {
    dispatch(setPrice(response.data.prices));
  }
}

export const setPrice = (content) => ({
  type: "SET_PRICE",
  payload: content,
});
