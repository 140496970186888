import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import SettingsPower from "@material-ui/icons/SettingsPower";
import Close from "@material-ui/icons/Close";
import Warning from "@material-ui/icons/Warning";
import Dvr from "@material-ui/icons/Dvr";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";

import {
  updateDesiredDeviceShadow,
  getDeviceShadow,
} from "../../shared/services/device.requests";

import { connect } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { setEvents } from "../../shared/redux/actions/eventsActions";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import Scheduler from "../Components/Scheduler.js";

import { UtilsService } from "../../shared/services/utils.service.ts";
const utilServices = new UtilsService();

function DeviceButtons(props) {
  let temp = props?.events?.state?.setPointTemperature;
  const [setTempModal, setSetTempModal] = React.useState(false);
  const [noticeModal, setNoticeModal] = React.useState(false);
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [scheduleData, setScheduleData] = React.useState(null);
  const [scheduleIndex, setScheduleIndex] = React.useState(null);
  const [setPointTemp, setSetPointTemp] = React.useState(temp);
  const [loading, setLoading] = React.useState(false);

  const [changedSchedule, setChangedSchedule] = React.useState(null);

  const parsedSchedules = utilServices.getSchedule(
    props?.events?.state?.schedule
  );
  const openScheduleModal = () => {
    setScheduleComponents(createScheduleComponents(parsedSchedules));
    setNoticeModal(true);
  };

  const updateSetpoint = () => {
    setSetTempModal(false);
    if (setPointTemp) {
      updateShadowTempCall(setPointTemp);
    }
  };

  const updateShadowTempCall = (setPointTemp) => {
    if (!props?.events?.state) return;

    const snn = props?.events?.snn;
    setLoading(true);
    updateDesiredDeviceShadow(snn, {
      setPointTemperature: Number(setPointTemp),
    }).then((res) => {
      if (res?.state) {
        let events = JSON.parse(JSON.stringify(props.events));
        getDeviceShadow(snn).then((res) => {
          if (res.state) {
            events.state = res.state.reported;
            events.state.setPointTemperature = setPointTemp;
            props.setEvents(events);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };

  const onOffToggle = () => {
    if (!props?.events?.state) return;
    const holiday = props?.events?.state?.holiday;
    const snn = props?.events?.snn;
    setLoading(true);
    updateDesiredDeviceShadow(snn, { holiday: !holiday }).then((res) => {
      if (res?.state) {
        let events = JSON.parse(JSON.stringify(props.events));
        getDeviceShadow(snn).then((res) => {
          if (res.state) {
            events.state = res.state.reported;
            events.state.holiday = holiday ? false : true;
            props.setEvents(events);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };
  const setTempModalO = () => {
    let temp = props?.events?.state?.setPointTemperature;
    if (temp) setSetPointTemp(temp);
    setSetTempModal(true);
  };

  const saveChangedSchedule = () => {
    let schedules = parsedSchedules;
    schedules[scheduleIndex] = changedSchedule.slice(0, -1);
    setScheduleComponents(createScheduleComponents(schedules));
    setScheduleModal(false);
  };
  const saveChangedScheduleToAll = () => {
    const changed = changedSchedule
      ? changedSchedule
      : scheduleComponents[scheduleIndex];
    let schedules = parsedSchedules;
    schedules = schedules.map((item) => {
      return [item[0], ...changed.slice(1, -1)];
    });
    setScheduleComponents(createScheduleComponents(schedules));
    setScheduleModal(false);
  };

  const createScheduleComponents = (schedules) => {
    const clone = JSON.parse(JSON.stringify(schedules));
    return clone.map((day, i) => {
      day.push(
        <Button
          size="sm"
          round
          color="transparent"
          onClick={() => {
            setScheduleData([...day]);
            setScheduleIndex(i);
            setScheduleModal(true);
          }}
        >
          <Dvr />
        </Button>
      );
      return day;
    });
  };

  const [scheduleComponents, setScheduleComponents] = React.useState(
    createScheduleComponents(parsedSchedules)
  );

  const updateScheduleDay = (update) => {
    setChangedSchedule(update);
  };

  const scheduleToString = (schedule) => {
    return schedule
      .map((itm) => {
        return (itm[1] + itm[2] + itm[3] + itm[4]).replaceAll(":", "");
      })
      .join(",");
  };

  const submitScheduleChanges = () => {
    const scheduleString = scheduleToString(scheduleComponents);
    setNoticeModal(false);
    const snn = props?.events?.snn;
    setLoading(true);
    updateDesiredDeviceShadow(snn, { schedule: scheduleString }).then((res) => {
      if (res?.state) {
        let events = JSON.parse(JSON.stringify(props.events));
        getDeviceShadow(snn).then((res) => {
          if (res.state) {
            events.state = res.state.reported;
            events.state.schedule = scheduleString;
            props.setEvents(events);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };

  const incrementTemp = (increment) => {
    const newTemp = setPointTemp + increment;
    if (newTemp < 50 || newTemp > 70) return;
    setSetPointTemp(newTemp);
  };

  const holiday = props?.events?.state?.holiday;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <Button
              className="dash-device-button"
              color="success"
              onClick={() => onOffToggle()}
            >
              {!loading ? (
                <span>
                  <SettingsPower className={classes.icons} /> Switch
                  {holiday ? " ON" : " OFF"}
                </span>
              ) : (
                <h6 className={classes.cardTitle} style={{ color: "white" }}>
                  <i className="fas fa-spinner fa-pulse"></i>
                </h6>
              )}
            </Button>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <Button
              color="danger"
              className="dash-device-button"
              onClick={() => setTempModalO()}
            >
              {!loading ? (
                <span>
                  <Warning className={classes.icons} /> SET TEMP{" "}
                  {temp ? temp + "°" : ""}
                </span>
              ) : (
                <h6 className={classes.cardTitle} style={{ color: "white" }}>
                  <i className="fas fa-spinner fa-pulse"></i>
                </h6>
              )}
            </Button>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <Button
              color="warning"
              className="dash-device-button"
              onClick={() => openScheduleModal()}
            >
              {!loading ? (
                <span>
                  <Close className={classes.icons} /> SCHEDULE
                </span>
              ) : (
                <h6 className={classes.cardTitle} style={{ color: "white" }}>
                  <i className="fas fa-spinner fa-pulse"></i>
                </h6>
              )}
            </Button>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={setTempModal}
        keepMounted
        onClose={() => setSetTempModal(false)}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Set Geyser Temperature</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <span key="key">
            <div style={{ textAlign: "center" }}>
              <Button
                color="info"
                size="sm"
                round
                className={classes.firstButton}
                onClick={() => incrementTemp(-1)}
              >
                <Remove className={classes.icon} />
              </Button>
              <h3 style={{ display: "inline-block", margin: "5px" }}>
                {setPointTemp}
              </h3>
              <Button
                color="info"
                size="sm"
                round
                className={classes.lastButton}
                onClick={() => incrementTemp(1)}
              >
                <Add className={classes.icon} />
              </Button>
            </div>
          </span>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center" }}
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button color="danger" onClick={() => setSetTempModal(false)} round>
            <Close className={classes.modalClose} />
          </Button>
          <Button
            onClick={() => {
              updateSetpoint();
            }}
            color="warning"
            round
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={noticeModal}
        keepMounted
        onClose={() => setNoticeModal(false)}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Heating Schedule</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <Table
            tableHead={["", "AM Start", "AM End", "PM Start", "PM End", ""]}
            tableData={scheduleComponents}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button color="warning" onClick={() => setNoticeModal(false)} round>
            <Close className={classes.modalClose} />
          </Button>
          <Button onClick={() => submitScheduleChanges()} color="info" round>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={scheduleModal}
        keepMounted
        onClose={() => setScheduleModal(false)}
        aria-labelledby="schedule-modal-slide-title"
        aria-describedby="schedule-modal-slide-description"
      >
        <DialogTitle
          id="schedule-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3 className={classes.modalTitle}>Set Schedule</h3>
        </DialogTitle>
        <DialogContent
          id="schedule-modal-slide-description"
          className={classes.modalBody}
        >
          {scheduleData && scheduleModal ? (
            <Scheduler day={scheduleData} updateDay={updateScheduleDay} />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button color="info" round onClick={() => setScheduleModal(false)}>
            Cancel
          </Button>
          <Button
            color="warning"
            round
            onClick={() => saveChangedScheduleToAll()}
          >
            Save to all
          </Button>
          <Button color="danger" round onClick={() => saveChangedSchedule()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeviceButtons.propTypes = {
  day: PropTypes.array,
  updateDay: PropTypes.func,
  events: PropTypes.any,
  setEvents: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    events: state.eventsReducer.events,
  };
};

export default connect(mapStateToProps, { setEvents })(DeviceButtons);
