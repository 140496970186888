import { useState } from 'react';
import environment from '../../config';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = tokenString;
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    console.log('setting Token', userToken);
    sessionStorage.setItem('token', userToken);

    setToken(userToken);
    handleLogout(userToken);
  };

  const handleLogout = (useToken) => {
    if (!useToken) {
      sessionStorage.clear();
      localStorage.clear();
      const url = environment.socket.logoutUrl;
      window.location.href = url;
    }
  };

  return {
    setToken: saveToken,
    token,
  };
}
