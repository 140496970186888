import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import environment from "../../config";
import { AuthService } from '../services/auth.service';


const httpLink = createHttpLink({
  uri: environment.base + "/websocket-server/graphql",
});

const logoutLink = onError(({ networkError }) => {
  if (networkError.statusCode === 500) {
    const authService = new AuthService();
    authService.init();
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export default client;
