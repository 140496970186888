import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ChartsDashboard from "./ChartDash.js";
import MobileVerify from "./MobileVerify.js";
import FloorPlan from "../../views/Charts/FloorPlan.js";
import environment from "../../config";

function DashBoard(props) {
  const isDiscoverAccount = props?.user?.username === environment.testEmail;
  if (!isDiscoverAccount && props?.user?.isMobileNoVerified === false) {
    const mobileNo = props?.user?.mobileNo ? props.user.mobileNo + "" : "";
    const localShow = localStorage.getItem(props.user.snn + "otpRequested");
    const showVerifyNum = localShow ? false : true;
    return <MobileVerify mobileNo={mobileNo} showVerifyNum={showVerifyNum} />;
  }

  if (!props?.queryData?.deviceSnn) return <br />;
  const isSecDev = props?.queryData?.deviceSnn?.includes("SECDEV");
  return isSecDev ? <FloorPlan /> : <ChartsDashboard />;
}

DashBoard.propTypes = {
  queryData: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    queryData: state.eventsReducer.queryData,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps)(DashBoard);
