import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import ZoneSettings from "../Components/ZoneSettings";

import { connect } from "react-redux";

import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

import {
  getDeviceMeta,
  updateZoneDeviceInfo,
} from "../../shared/services/device.requests";

import interact from "interactjs";
import {
  fetchZoneData,
  setZoneData,
  saveZoneAndFloorPlanDataToDB,
  updateViolatedZones,
} from "../../shared/redux/actions/zoneActions";
import {
  setFloorPlan,
  fetchFloorplanData,
} from "../../shared/redux/actions/floorPlanActions";

import { SniotService } from "../../shared/services/sniot.service";

const useButtonStyles = makeStyles(buttonStyles);

let initialized = false;
let editing = false;
let globalScale = 1;
let showZoneSettings = false;
let zoomActive = false;
let dragging = false;
let panZoomClickCount = 0;

function FloorPlan(props) {
  const buttonsClass = useButtonStyles();
  const [selectedZone, setSelectedZone] = React.useState(null);
  const [mainDeviceData, setMainDeviceData] = React.useState();

  const savePosition = () => {
    editing = false;
    setTimeout(() => {
      const rooms = getDomRoomPosition();
      const zonePositions = getDomZonePosition();
      saveZoneAndFloorPlanDataToDB(rooms, zonePositions);
      refreshUI();
    }, 500);
  };
  const getDomRoomPosition = () => {
    unsetSelectedRoom();
    const rectDom = document.getElementsByClassName("rooms");
    let rect = [];
    Array.from(rectDom).forEach((element) => {
      const rectX = element.getAttribute("data-x");
      const rectY = element.getAttribute("data-y");
      rect.push({
        x: rectX,
        y: rectY,
        height: element.offsetHeight,
        width: element.offsetWidth,
      });
    });

    return rect;
  };

  const saveRoomPosition = () => {
    unsetSelectedRoom();
    let rect = getDomRoomPosition();
    saveRooms(rect);
  };

  const getDomZonePosition = () => {
    const rectDom = document.getElementsByClassName("zone-name");
    let rect = [{ x: 0, y: 0 }];
    Array.from(rectDom).forEach((element) => {
      const rectX = element.getAttribute("data-x");
      const rectY = element.getAttribute("data-y");
      rect.push({
        x: rectX,
        y: rectY,
      });
    });
    return rect;
  };

  const reSetPositions = () => {
    unsetSelectedRoom();
    saveZones([]);
    saveRooms([]);
    props.fetchZoneData();
    console.log("reSetPositions");
  };

  const setSelectedRoom = (evt) => {
    if (!editing) return;
    const selected = evt.target;
    unsetSelectedRoom();

    if (selected) {
      // eslint-disable-next-line no-constant-condition
      if (false) {
        selected.classList.remove("room-clicked");
        selected.style["z-index"] = "unset";
      } else {
        selected.classList.add("room-clicked");
        selected.style["z-index"] = "5";
      }
    }
  };

  const unsetSelectedRoom = () => {
    const prev = document.getElementsByClassName("room-clicked");
    if (prev && prev[0]) {
      const old = [...prev][0];
      old.classList.remove("room-clicked");
      old.style["z-index"] = "unset";
    }
  };

  const addLayoutEdges = (itemsConst) => {
    let items = [...itemsConst];
    let layout = {
      maxX: 0,
      maxY: 0,
      minX: 10000,
      minY: 10000,
    };
    items.forEach((element) => {
      const x = Number(element.x);
      const y = Number(element.y);
      if (layout.maxX < x) layout.maxX = x;
      if (layout.maxY < y) layout.maxY = y;
      if (layout.minX > x) layout.minX = x;
      if (layout.minY > y) layout.minY = y;
    });

    const offset = 5000;

    items.push({
      corner: true,
      x: layout.maxX + offset,
      y: layout.maxY + offset,
      width: 10,
      height: 10,
    });
    items.push({
      corner: true,
      x: 0,
      y: layout.maxY + offset,
      width: 10,
      height: 10,
    });
    items.push({
      corner: true,
      x: 0,
      y: 0,
      width: 10,
      height: 10,
    });
    items.push({
      corner: true,
      x: layout.maxX + offset,
      y: 0,
      width: 10,
      height: 10,
    });
    return items;
  };
  const createRooms = (itemsConst) => {
    if (!itemsConst) return;

    const items = addLayoutEdges(itemsConst);
    return items.map((element, i) => {
      const style = {
        width: element.width,
        height: element.height,
        transform: "translate(" + element.x + "px, " + element.y + "px)",
      };

      const classNames = element.corner ? "edge-points" : "resize-dragx rooms";
      return (
        <div
          key={i}
          data-key={i}
          className={classNames}
          style={style}
          data-x={element.x}
          data-y={element.y}
          onMouseDown={(evt) => setSelectedRoom(evt)}
        ></div>
      );
    });
  };

  const setButtonColor = (zoneId, hidden) => {
    if (props.violatedZones && props.violatedZones.includes(zoneId)) {
      return "danger";
    }
    return hidden ? "tumblr" : "info";
  };

  const createZones = () => {
    const savedPositions = getZoneData();
    const zonePositions = savedPositions
      ? savedPositions
      : props.zoneData?.positions;

    const deviceData = mainDeviceData;
    const hiddenZones = deviceData?.zonesHidden;
    const zoneRoomTypes = deviceData?.zoneRoomTypes;
    const zoneTypes = deviceData?.zoneTypes;
    if (!deviceData) return;
    const zoneCount = deviceData.zoneCount ? deviceData.zoneCount : 8;
    let newZones = [];
    for (let i = 1; i < zoneCount + 1; i++) {
      const roomName = deviceData?.zoneNames ? deviceData?.zoneNames[i] : null;
      const showName = roomName ? `${roomName} (${i})` : `Zone ${i}`;
      const realName = roomName ? `${roomName}` : `Zone ${i}`;
      const hideZone = hiddenZones ? hiddenZones[i] !== undefined : false;
      const zoneRoomType = zoneRoomTypes ? zoneRoomTypes[i] : null;
      const zoneType = zoneTypes ? zoneTypes[i] : null;
      const element = {
        id: i,
        name: realName,
        position: {
          x: zonePositions && zonePositions[i]?.x ? zonePositions[i]?.x : 0,
          y: zonePositions && zonePositions[i]?.y ? zonePositions[i]?.y : 0,
        },
        hidden: hideZone,
        zoneRoomType,
        zoneType,
      };

      let style = {
        position: "absolute",
        transform:
          "translate(" +
          element.position.x +
          "px, " +
          element.position.y +
          "px)",
        fontSize: "10px",
      };

      if (!element.hidden || editing) {
        newZones[i] = (
          <Button
            onClick={() => openZoneConfig(element)}
            key={i}
            data-key={i}
            className="fit-content zone-name"
            color={setButtonColor(i, element.hidden)}
            round
            style={style}
            data-x={element.position.x}
            data-y={element.position.y}
          >
            {showName}
          </Button>
        );
      }
    }
    return newZones;
  };

  const openZoneConfig = (zone) => {
    if (dragging) return;
    setSelectedZone(zone);
    showZoneSettings = true;

    refreshUI();
  };

  const toggleZoneSet = () => {
    showZoneSettings = showZoneSettings ? false : true;
    refreshUI();
  };
  const getRoomData = () => {
    if (props.floorPlan) {
      return props.floorPlan;
    }
    return [{ x: 0, y: 0, height: 100, width: 100 }];
  };

  const getZoneData = () => {
    if (!props.zoneData) return;
    if (props.zoneData.positions) return props.zoneData.positions;
    return;
  };

  const roomData = getRoomData();

  const saveRooms = (rects) => {
    // localStorage.setItem("rooms", JSON.stringify(rects));
    props.setFloorPlan([...rects]);
  };

  const saveZones = (rects) => {
    // localStorage.setItem("zones", JSON.stringify(rects));
    props.setZoneData({ zones: props.zoneData?.zones, positions: rects });
  };

  const addRoom = () => {
    if (!editing) return;
    console.log("More Rooms!");
    let items = getRoomData();
    items.push({ x: 0, y: 0, height: 100, width: 100 });
    saveRooms(items);
  };

  const removeRoom = () => {
    if (!editing) return;
    console.log("Remove Room!");
    let items = getRoomData();
    const prev = document.getElementsByClassName("room-clicked");

    if (prev && prev[0]) {
      const old = [...prev][0];
      const removeIndex = old.getAttribute("data-key");
      items.splice(removeIndex, 1);
      saveRooms(items);
    }
  };

  const activateListeners = () => {
    const opt = {
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      // keep the edges inside the parent
      // restrictEdges: {
      //   outer: 'parent',
      //   endOnly: true
      // },
      // minimum size
      restrictSize: {
        min: { width: 100, height: 50 },
      },
      listeners: {
        move: onResize,
        end: () => {
          saveRoomPosition();
          dragging = false;
        },
      },
      enabled: editing,
      // inertia: true
    };

    const dragOpt = {
      listeners: {
        // call this function on every dragmove event
        move: dragMoveListener,
        end: () => {
          setTimeout(() => {
            dragging = false;
          }, 100);
        },
      },
      // restrict: {
      // restriction: 'parent',
      // elementRect: { top: 0, left: 0, bottom: 0, right: 0 }
      // endOnly: true
      // },
      enabled: editing,
      // inertia: true
    };
    interact(".zone-name").draggable(dragOpt);

    interact(".resize-dragx").draggable(dragOpt).resizable(opt);

    function onResize(event) {
      let target = event.target,
        x = parseFloat(target.getAttribute("data-x")) || 0,
        y = parseFloat(target.getAttribute("data-y")) || 0;

      // update the element's style
      target.style.width = event.rect.width / globalScale + "px";
      target.style.height = event.rect.height / globalScale + "px";

      // translate when resizing from top or left edges
      x += event.deltaRect.left / globalScale;
      y += event.deltaRect.top / globalScale;

      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px," + y + "px)";

      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    }

    function dragMoveListener(event) {
      if (!editing) return;
      dragging = true;
      const target = event.target; //,
      // keep the dragged position in the data-x/data-y attributes
      let x =
        (parseFloat(target.getAttribute("data-x")) || 0) +
        event.dx / globalScale;
      let y =
        (parseFloat(target.getAttribute("data-y")) || 0) +
        event.dy / globalScale;

      // y = y >= 0? y: 0
      // x = x >= 0? x: 0
      // translate the element
      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px, " + y + "px)";

      // update the position attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    }
  };

  const editToggle = () => {
    unsetSelectedRoom();
    editing = editing ? false : true;
    refreshUI();
  };

  const refreshUI = () => {
    const roomData = getRoomData();
    props.setFloorPlan([...roomData]);
  };

  const panZoom = () => {
    console.log("init Pan Zoom");
    let editor = document.getElementById("editor");
    if (!editor) return;
    let editorCanvas = editor.querySelector(".canvas");
    let scale = 1.0;

    zoomActive = true;
    const minScale = 0.1;
    const maxScale = 8;
    const scaleStep = 0.008;

    editor.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();

        requestAnimationFrame(() => {
          if (e.ctrlKey) {
            scale -= e.deltaY * scaleStep;

            if (scale < minScale) {
              scale = minScale;
            }
            globalScale = scale;

            if (scale > maxScale) {
              scale = maxScale;
            }

            if (scale < 1) {
              editorCanvas.style.transformOrigin = "50% 50% 0";
            } else {
              editorCanvas.style.transformOrigin = "0 0 0";
            }

            editorCanvas.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, 0)`;

            let rect = editorCanvas.getBoundingClientRect();

            const ew = rect.width;
            const eh = rect.height;

            const mx = e.x - editor.offsetLeft;
            const my = e.y - editor.offsetTop;

            editor.scroll(
              (ew - editor.offsetWidth) * (mx / editor.clientWidth),
              (eh - editor.offsetHeight) * (my / editor.clientHeight)
            );
          } else {
            editor.scrollTop += e.deltaY;
            editor.scrollLeft += e.deltaX;
          }
        });
      },
      { passive: false }
    );
  };

  if (!zoomActive) {
    panZoom();
  }

  const getViolatedZones = (deviceId, deviceProfile) => {
    const sniotService = new SniotService(window.SensorTracker);

    sniotService.getClient().subscribe((client) => {
      if (client) {
        sniotService
          .bindCollection(client.deviceShadow.getShadowCollection(), {
            profile: deviceProfile,
            serial: deviceId,
          })
          .subscribe((view) => {
            const shadow = view.data.toArray()[0];
            if (shadow) {
              props.updateViolatedZones(shadow.state.reported["violatedZones"]);
            }
          });
      }
    });
  };

  const fakeViolateZones = () => {
    panZoomClickCount++;
    if (panZoomClickCount === 7) {
      let zones = [];
      for (let i = 1; i < mainDeviceData?.zoneCount + 1; i++) {
        if (mainDeviceData.zonesHidden[i] !== false) zones.push(i);
      }

      let count = 1;
      setInterval(() => {
        if (count > zones.length) count = 1;
        props.updateViolatedZones([count]);
        count++;
      }, 2000);
    }
  };

  if (!initialized && props?.events?.snn) {
    initialized = true;
    getDeviceMeta(props?.events?.snn, "secdev:deviceInfo").then((resp) => {
      setMainDeviceData(resp);
    });
    getViolatedZones(props?.events?.serial, props?.events?.profile);
  }
  activateListeners();
  const editButtons = (editing) => {
    if (!editing) return;
    return (
      <>
        <Button
          color="info"
          className={buttonsClass.marginRight}
          onClick={() => {
            savePosition();
          }}
        >
          Save
        </Button>
        <Button
          color="success"
          className={buttonsClass.marginRight}
          onClick={() => {
            reSetPositions();
          }}
        >
          Reset
        </Button>
        <Button
          color="warning"
          className={buttonsClass.marginRight}
          onClick={() => {
            addRoom();
          }}
        >
          Add Room
        </Button>
        <Button
          color="danger"
          className={buttonsClass.marginRight}
          onClick={() => {
            removeRoom();
          }}
        >
          Delete Room
        </Button>

        <Button
          color="danger"
          className={buttonsClass.marginRight}
          onClick={() => {
            fakeViolateZones();
          }}
        >
          Pan Zoom {globalScale.toFixed(3)}
        </Button>
      </>
    );
  };

  const saveZoneData = (data) => {
    const snn = props?.events?.snn;
    if (!snn) return;
    updateZoneDeviceInfo(snn, data).then((deviceInfo) => {
      setMainDeviceData(deviceInfo);
    });
  };
  return (
    <div>
      <Card chart id="floor-plan-dashboard">
        <div id="editor" className="resize-container">
          <div className="canvas">
            {createRooms(roomData)}
            {createZones()}
          </div>
        </div>
        <div
          className={buttonsClass.cardContentLeft}
          style={{ textAlign: "center" }}
        >
          <Button
            color="primary"
            className={buttonsClass.marginRight}
            onClick={() => {
              editToggle();
            }}
          >
            Edit
          </Button>
          {editButtons(editing)}
          {showZoneSettings ? (
            <ZoneSettings
              zoneData={selectedZone}
              showModal={showZoneSettings}
              setModalActive={() => toggleZoneSet()}
              updateZoneData={(data) => saveZoneData(data)}
            />
          ) : null}
        </div>
      </Card>
    </div>
  );
}

FloorPlan.propTypes = {
  setFloorPlan: PropTypes.func,
  setZoneData: PropTypes.func,
  fetchZoneData: PropTypes.func,
  setModalActive: PropTypes.func,
  updateViolatedZones: PropTypes.func,
  showModal: PropTypes.bool,
  floorPlan: PropTypes.any,
  zoneData: PropTypes.any,
  events: PropTypes.any,
  zoneRoomType: PropTypes.number,
  zoneType: PropTypes.number,
  violatedZones: PropTypes.array,
};
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    events: state.eventsReducer.events,
    loading: state.eventsReducer.loading,
    floorPlan: state.floorPlanReducer.floorPlan,
    zoneData: state.zoneReducer.zoneData,
    violatedZones: state.zoneReducer.violatedZones,
  };
};

export default connect(mapStateToProps, {
  setFloorPlan,
  fetchFloorplanData,
  fetchZoneData,
  setZoneData,
  updateViolatedZones,
})(FloorPlan);
