const initialState = {
  ciDevices: null,
  events: null,
  queryData: {
    deviceSnn: null,
    startTs: null,
    endTs: null,
  },
  loading: false,
  loadingCount: 0,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EVENTS":
      return { ...state, events: action.payload };
    case "SET_CI_DEVICES":
      return { ...state, ciDevices: action.payload };
    case "SET_QUERY":
      return { ...state, queryData: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_LOADING_COUNT":
      return { ...state, loadingCount: (state.loadingCount += action.payload) };
    default:
      return state;
  }
};

export default eventReducer;
