import React from "react";
import PropTypes from "prop-types";

// core components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

function ZoneSelect(props) {
  const classes = useStyles();
  const index = props?.selectIndex ? props?.selectIndex : 0;
  const [select, setSelect] = React.useState(index);
  const createSelectOptions = (options) => {
    return options.map((option, i) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={i}
          key={i}
        >
          {option}
        </MenuItem>
      );
    });
  };

  const handleSelect = (event) => {
    const index = event.target.value;
    setSelect(index);
    props.selectionChange(index);
  };

  return (
    <FormControl
      className={
        classes.top + " " + classes.search + " selectFormUnderlineColor"
      }
    >
      <label className="MuiInputLabel-shrink">{props.label}</label>
      <Select
        value={select}
        onChange={handleSelect}
        inputProps={{
          id: "room-select",
        }}
        MenuProps={{
          id: "room-select",
        }}
        classes={{
          select: classes.select + " select-color",
        }}
        style={{ width: "125px", marginTop: "-0.9px", ...props.style }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Choose {props.label}
        </MenuItem>
        {props.options ? createSelectOptions(props.options) : null}
      </Select>
    </FormControl>
  );
}

ZoneSelect.propTypes = {
  label: PropTypes.string,
  selectIndex: PropTypes.number,
  options: PropTypes.array,
  style: PropTypes.any,
  selectionChange: PropTypes.func,
};

export default ZoneSelect;
