/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./shared/redux/store";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { AuthService } from "./shared/services/auth.service";
import HttpInterceptorServices from "./shared/services/interceptors.service";
import { ApolloProvider } from "@apollo/client";

import graphClient from "./shared/graphql/client";

const hist = createBrowserHistory();
const authService = new AuthService();
authService.init();
const interseptors = HttpInterceptorServices();
interseptors.init();

const refresh_token = sessionStorage.getItem("refresh_token");
const redirectRoute =
  !refresh_token || refresh_token === "undefined"
    ? "/auth/login-page"
    : "/admin/dashboard";

ReactDOM.render(
  <ApolloProvider client={graphClient}>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Redirect from="/" to={redirectRoute} />
        </Switch>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
