import environment from "../../config.js";
const axios = require("axios").default;

export default function HttpInterceptorServices() {
  const init = () => {
    axios.interceptors.request.use(
      async (config: any) => {
        if (!config.url) return config;
        let serverCallUrl = new URL(config.url);
        if (serverCallUrl.pathname.includes("/auth")) return config;
        config = setTokenHeaders(config);
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  };

  const setTokenHeaders = (req: any) => {
    if (
      !req.url.includes(environment.base) ||
      req.url === environment.socket.oauthTokenUrl
    )
      return req;

    const spoof = sessionStorage.getItem("spoof");
    const token =
      spoof && spoof !== null && spoof !== "null"
        ? spoof
        : sessionStorage.getItem("token");
    if (token) {
      req.headers["authorization"] = "Bearer " + token;
      req.headers["content-type"] = "application/json";
      req.headers["cache-control"] = `no-cache`;
    } else {
      console.log("No TOKEN!!!!");
      logOut();
    }
    return req;
  };
  const logOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    const url =
      environment.socket.logoutUrl +
      "?&redirect_uri=" +
      encodeURIComponent(document.location.href);
    window.location.href = url;
  };

  return {
    init,
    setTokenHeaders,
    logOut,
  };
}
