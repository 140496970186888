/*eslint-disable*/
import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { UtilsService } from '../../shared/services/utils.service';

import allCharts from "variables/deviceCharts";

import { connect } from 'react-redux'

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
const tooltip = require('chartist-plugin-tooltips');
require('chartist-plugin-legend');
var Chartist = require("chartist");
const useStyles = makeStyles(styles);
const utilServices = new UtilsService();

function clone(obj){
  let newObj = {}
  const keys = Object.keys(obj)
  keys.forEach(key => {
    newObj[key] = typeof obj[key] === 'object'? Object.assign({}, obj[key]): obj[key]
  })
  return newObj
}


const loadChart = (path) => {
  const chartKeys = Object.keys(allCharts)

  const charts = chartKeys.map(key => {
    return allCharts[key]
  })

  const found = charts.find(chart => path.includes(chart.path))
  if(found) return found
  return charts[0];
}

const updateLables = () => {
  setTimeout(() => {
    const elements = document.querySelectorAll('.ct-label');
    elements.forEach(element => {
      element.setAttribute('style', 'justify-content: center');
    });
  }, 500);
}

function OneCharts(props) {
  const path = props.location.pathname
  const localChart = clone(loadChart(path));
  const dataProp = localChart.dataProp ? localChart.dataProp: null

  utilServices.initChartData(localChart, props);

  const classes = useStyles();
  localChart.options.height = '500px'
  
  
  updateLables()
  return (
    <div style={{"paddingBottom": "0px"}}>
          <Card chart >
            <CardHeader color={localChart.backgroundColorLable} style={{ "textAlign": "center"}}>
            {
            !props.loading? 
              <ChartistGraph
                className="ct-chart-white-colors"
                data={localChart.data}
                type={localChart.type}
                options={localChart.options}
                listener={localChart.animation}
                style={{textAlign: "center"}}
              />
              : 
              <h1 >
                  <i className="fas fa-spinner fa-pulse"></i>
              </h1>
            }
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{localChart.title}</h4>
              <p className={classes.cardCategory}>{localChart.dataTypeSting}</p>
            </CardBody>
          </Card>
     </div>
  );
}
const mapStateToProps = state => {
  return { 
    user: state.userReducer.user, 
    events: state.eventsReducer.events,
    loading: state.eventsReducer.loading,
  }
}

export default connect(
  mapStateToProps
)(OneCharts);