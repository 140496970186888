import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
let moment = require("moment");
function Scheduler(props) {
  const [day, setDay] = React.useState([...props.day]);
  if (day[0] !== props.day[0]) {
    setDay(props.day);
  }

  const getScheduleDay = () => {
    return day ? day[0] : "";
  };

  const getSchedule = (day, index, timeSelect) => {
    if (!day) return;
    let time = day[index];
    if (timeSelect === 1) {
      return time.substring(0, time.indexOf(":"));
    } else {
      return time.substring(time.indexOf(":") + 1);
    }
  };

  const updateSchedule = (index, timeSelect, interval, amPm) => {
    if (!day) return;

    let time = day[index];
    if (!time) return;
    let hours = Number(time.substring(0, time.indexOf(":")));
    let mins = Number(time.substring(time.indexOf(":") + 1));
    let updated = "";
    if (timeSelect === 1) {
      let newTime = hours + interval;

      if (amPm === "Am") {
        if (newTime < 0) newTime = 11;
        if (newTime > 11) newTime = 0;
      } else {
        if (newTime < 12) newTime = 23;
        if (newTime > 23) newTime = 12;
      }

      if (newTime < 10) newTime = "0" + newTime;
      if (mins < 10) mins = "0" + mins;

      updated = newTime + ":" + mins;
    } else {
      let newTime = mins + interval;
      if (newTime < 0) newTime = 59;
      if (newTime > 59) newTime = 0;
      if (newTime < 10) newTime = "0" + newTime;

      if (amPm === "Am") {
        if (hours < 0) hours = 12;
        if (hours > 12) hours = 0;
      } else {
        if (hours < 12) hours = 23;
        if (hours > 23) hours = 12;
      }
      if (hours < 10) hours = "0" + hours;

      updated = hours + ":" + newTime;
    }
    let newDay = [...day];
    newDay[index] = updated;

    const amStart = moment(newDay[1], "HH:mm").toDate().getTime();
    const amEnd = moment(newDay[2], "HH:mm").toDate().getTime();
    const pmStart = moment(newDay[3], "HH:mm").toDate().getTime();
    const pmEnd = moment(newDay[4], "HH:mm").toDate().getTime();

    if (amStart >= amEnd) {
      if (interval === 1) {
        newDay[2] = newDay[1];
      } else {
        newDay[1] = newDay[2];
      }
    }

    if (pmStart >= pmEnd) {
      if (interval === 1) {
        newDay[4] = newDay[3];
      } else {
        newDay[3] = newDay[4];
      }
    }
    setDay(newDay);
    props.updateDay(newDay);
  };
  return (
    <div className="timer-component">
      <h3>{getScheduleDay()}</h3>
      <div>
        <div>
          <h4>AM</h4>
          <div className="timers">
            <div className="timer">
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(1, 1, 1, "Am")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 1, 1)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(1, 1, -1, "Am")}
                >
                  <Remove />
                </button>
              </div>
              <span>:</span>
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(1, 2, 1, "Am")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 1, 2)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(1, 2, -1, "Am")}
                >
                  <Remove />
                </button>
              </div>
            </div>
            <span>to</span>
            <div className="timer">
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(2, 1, 1, "Am")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 2, 1)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(2, 1, -1, "Am")}
                >
                  <Remove />
                </button>
              </div>
              <span>:</span>
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(2, 2, 1, "Am")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 2, 2)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(2, 2, -1, "Am")}
                >
                  <Remove />
                </button>
              </div>
            </div>
          </div>
          <h4>PM</h4>
          <div className="timers">
            <div className="timer">
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(3, 1, 1, "Pm")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 3, 1)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(3, 1, -1, "Pm")}
                >
                  <Remove />
                </button>
              </div>
              <span>:</span>
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(3, 2, 1, "Pm")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 3, 2, "Pm")}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(3, 2, -1, "Pm")}
                >
                  <Remove />
                </button>
              </div>
            </div>
            <span>to</span>
            <div className="timer">
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(4, 1, 1, "Pm")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 4, 1)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(4, 1, -1, "Pm")}
                >
                  <Remove />
                </button>
              </div>
              <span>:</span>
              <div className="number-ticker">
                <button
                  color="primary"
                  onClick={() => updateSchedule(4, 2, 1, "Pm")}
                >
                  <Add />
                </button>
                <div className="number-lbl">{getSchedule(day, 4, 2)}</div>
                <button
                  color="primary"
                  onClick={() => updateSchedule(4, 2, -1, "Pm")}
                >
                  <Remove />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Scheduler.propTypes = {
  day: PropTypes.array,
  updateDay: PropTypes.func,
};

export default Scheduler;
