var Chartist = require("chartist");
var moment = require("moment");

var delays = 8,
  durations = 50;
var delays2 = 80,
  durations2 = 500;

const dateLable = (value, index, interval = 20) => {
  return index % interval === 0 && typeof value === "number"
    ? moment(new Date(value)).format("hh:mm DD/MM")
    : "";
};

const chartBaloon = (value) => {
  const val = Math.abs(Chartist.getMultiValue(value)) / 10;
  return val < 5 ? 5 : val > 70 ? 70 : val;
}
const internalTempChart = {
  path: "/internal-temp",
  backgroundColorLable: "info",
  title: "Internal Temp Data",
  category: "dailySalesChart",
  dataProp: "int",
  dataTypeSting: "°C",
  type: "Line",
  data: {
    labels: [""],
    series: [[0]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return dateLable(value, index);
      },
    },
    height: "300px",
  },

  // for animation
  animation: {
    draw: (data) => {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        let opacity = 1;
        if (data.axisY.axisLength < 400) {
          opacity = 0;
        }
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: opacity,
            easing: "ease",
          },
        });
      }
    },
  },
};

const voltageUsedChart = {
  path: "/voltage",
  backgroundColorLable: "danger",
  title: "Voltage Used",
  category: "Voltage used per day",
  type: "Line",
  dataProp: "vol",
  dataTypeSting: "V",
  data: {
    labels: [""],
    series: [[0]],
  },

  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 10,
    }),
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return dateLable(value, index);
      },
    },
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    showPoint: true,
    height: "300px",
  },
  animation: {
    draw: (data) => {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 0,
            easing: "ease",
          },
        });
      }
    },
  },
};

const externalTempChart = {
  path: "/external-temp",
  backgroundColorLable: "rose",
  title: "External Temp",
  category: "External Temp",
  dataProp: "ext",
  dataTypeSting: "°C",
  type: "Line",
  data: {
    labels: [
      "'06",
      "'07",
      "'08",
      "'09",
      "'10",
      "'11",
      "'12",
      "'13",
      "'14",
      "'15",
    ],
    series: [[10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 20,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    classNames: {
      point: "ct-point ct-white",
      line: "ct-line ct-white",
    },
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return dateLable(value, index);
      },
    },
    height: "300px",
  },
  animation: {
    draw: (data) => {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        let opacity = 1;
        if (data.axisY.axisLength < 400) {
          opacity = 0;
        }
        data.element.attr({
          strokeWidth: "5px",
        });
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: opacity,
            easing: "ease",
          },
        });
      }
    },
  },
};

const energyDataChart = {
  path: "/energy",
  backgroundColorLable: "warning",
  title: "Energy Chart",
  category: "energyDataChart",
  dataProp: "ene",
  dataTypeSting: "Wh in min",
  type: "Bar",
  data: {
    labels: [""],
    series: [[0]],
  },
  options: {
    seriesBarDistance: 10,
    axisX: {
      showGrid: false,
    },
    height: "300px",
  },
  animation: {
    draw: (data) => {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};
const dailyEnergyUsedPieChart = {
  path: "/daily-energy",
  backgroundColorLable: "success",
  title: "Daily Energy",
  category: "colouredPieChart",
  dataProp: "ene",
  dataType: "time",
  dataTypeSting: "Wh in min",
  type: "Pie",
  increment: "week",
  data: {
    labels: ["62%", "32%", "6%"],
    series: [62, 32, 6],
  },
  options: {
    showLabel: false,
    height: "300px",
  },
  animation: {},
};

const ambientTempChart = {
  path: "/ambient-temp",
  backgroundColorLable: "info",
  title: "Ambient Temp",
  category: "colouredLineChart",
  dataProp: "amb",
  dataTypeSting: "°C",
  type: "Line",
  data: {
    labels: [""],
    series: [[0]],
  },

  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 10,
    }),
    axisY: {
      showGrid: true,
      offset: 40,
    },
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function (value, index) {
        return dateLable(value, index);
      },
    },
    low: 20,
    high: 50,
    showPoint: true,
    height: "300px",
  },
  animation: {
    draw: (data) => {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        let opacity = 1;
        if (data.axisY.axisLength < 400) {
          opacity = 0;
        }
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: opacity,
            easing: "ease",
          },
        });
      }
    },
  },
};
const heatingTimeChart = {
  path: "/heating",
  backgroundColorLable: "danger",
  title: "Heating Time",
  category: "Minutes of active heating",
  dataProp: "het",
  dataType: "time",
  dataTypeSting: "Min",
  type: "Bar",
  data: {
    labels: [""],
    series: [[0]],
  },

  options: {
    height: "300px",
    axisX: {
      offset: 60,
      showGrid: false,
    },
  },
  animation: {
    draw: (data) => {
      if (data.type === "bar") {
        if (data.axisY.axisLength < 400) {
          data.element.attr({
            style: "stroke: #f44336",
          });
          data.group.append(
            new Chartist.Svg("circle", {
              cx: data.x2,
              cy: data.y2,
              r: chartBaloon(data.value),
              fill: "#f44336",
            }).animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2 - 200,
                from: 0,
                to: 1,
                easing: "ease",
              },
            })
          );
        }

        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

module.exports = {
  internalTempChart,
  ambientTempChart,
  voltageUsedChart,
  externalTempChart,
  energyDataChart,
  heatingTimeChart,
  dailyEnergyUsedPieChart,
};
