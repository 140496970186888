import {
  DEVICE_REPORT_EVENTS,
  SECDEV_DEVICE_REPORT_EVENTS,
} from "../../queries/deviceEvents.js";
import graphQlClient from "../../graphql/client";
import store from "../store";
import { getContainersByUser } from "../../services/device.requests";

export function fetchEventsData(queryData = null) {
  const state = store.getState();
  const stateQuery = state.eventsReducer.queryData;
  const query = queryData ? queryData : stateQuery;
  return (dispatch) => {
    getChartEvents(dispatch, query);
  };
}
export function initCiData() {
  return (dispatch) => {
    getContainersByUser()
      .then((ci_device) => {
        dispatch(setCiDevices(ci_device.result));
      })
      .catch((e) => {
        console.log("Ci Error:", e);
      });
  };
}
export async function getChartEvents(dispatch, queryData) {
  const reportFilter = {
    serial: queryData.deviceSnn,
    since: queryData.startTs,
    until: queryData.endTs,
  };

  const state = store.getState();
  const loading = state.eventsReducer.loading;

  if (!loading && reportFilter.serial) {
    const query = reportFilter.serial.includes("GEYSER")
      ? DEVICE_REPORT_EVENTS
      : SECDEV_DEVICE_REPORT_EVENTS;
    dispatch(setEventsLoading(true));
    graphQlClient
      .query({
        query: query,
        variables: { ...reportFilter },
      })
      .then((result) => {
        const serial = result?.data?.device?.serial;
        const active = result?.data?.device?.active;
        if (active) {
          const chartData =
            active?._vega?.geyserHistory?.data[0]?.values?.aggregations?.time
              ?.buckets;
          const reported = active?.shadowReference?.state?.reported;
          const profile = result?.data?.device?.profile;
          const meta = active?.meta ? active.meta["secdev:deviceInfo"] : null;
          if (meta && meta.floorPlan) dispatch(setFloorplan(meta.floorPlan));
          if (meta) setDeviceMetaToZoneData(dispatch, meta);

          dispatch(
            setEvents({
              state: reported,
              data: chartData,
              serial,
              active,
              snn: active.snn,
              profile,
            })
          );
        }
        dispatch(setEventsLoading(false));
      })
      .catch((error) => {
        console.log("Events Api Error:", error);
        dispatch(setEventsLoading(false));
      });
  }
}

export function setEventsQuery(queryData) {
  return (dispatch) => {
    dispatch(setQuery(queryData));
  };
}

export function setEventsLoading(queryData) {
  return (dispatch) => {
    if (queryData) {
      dispatch(setLoadingCount(1));
    } else {
      dispatch(setLoadingCount(-1));
    }
    const state = store.getState();
    const loadingCount = state.eventsReducer.loadingCount;
    if (loadingCount < 1) {
      dispatch(setLoading(false));
      dispatch(setLoadingCount(Math.abs(loadingCount)));
    } else {
      dispatch(setLoading(true));
    }
  };
}

function setDeviceMetaToZoneData(dispatch, meta) {
  if (meta) {
    const zoneCoordinates = meta?.zoneCoordinates;
    const zoneNames = meta?.zoneNames;
    if (zoneNames || zoneCoordinates) {
      dispatch(setZone({ positions: zoneCoordinates, zones: zoneNames }));
    }
  }
}

export const setLoadingCount = (content) => ({
  type: "SET_LOADING_COUNT",
  payload: content,
});
export const setLoading = (content) => ({
  type: "SET_LOADING",
  payload: content,
});
export const setQuery = (content) => ({
  type: "SET_QUERY",
  payload: content,
});
export const setEvents = (content) => ({
  type: "SET_EVENTS",
  payload: content,
});
export const setFloorplan = (content) => ({
  type: "SET_FLOORPLAN",
  payload: content,
});

export const setZone = (content) => ({
  type: "SET_ZONEDATA",
  payload: content,
});
export const setCiDevices = (content) => ({
  type: "SET_CI_DEVICES",
  payload: content,
});
