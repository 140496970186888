import environment from "../../config.js";
const axios = require("axios").default;

export async function registerNewUser(userData) {
  const api =
    `${environment.api}/geyser-adapter/register` +
    "?client_id=" +
    environment.clientId;

  const { firstName, lastName, emailAddress, mobileNo, password } = userData;
  const payload = {
    client_id: environment.clientId,
    client_secret: environment.clientSecret,
    firstName,
    lastName,
    emailAddress,
    mobileNo,
    password,
  };

  let response = await axios.post(api, payload, { validateStatus: false });
  if (response.status !== 200 && response.status !== 204) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response;
  }
}

export async function requestOtp(number) {
  const api = `${environment.api}/credentials-authority/accounts/start-set-mobile-no`;
  const payload = { phoneNumber: number };
  let response = await axios.post(api, payload, { validateStatus: false });

  if (response.status !== 200 && response.status !== 204) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response;
  }
}

export async function sendOtp(otp, registerCellNo) {
  const api = `${environment.api}/credentials-authority/accounts/finish-set-mobile-no`;
  const payload = { otp, phoneNumber: registerCellNo };

  let response = await axios.post(api, payload, { validateStatus: false });

  if (response.status !== 200 && response.status !== 204) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response;
  }
}
