import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { UtilsService } from "./utils.service";
import environment from "../../config.js";
import jwt_decode from "jwt-decode";
import { User } from "../models/user";
import { SensorTrackerService } from "./sensor-tracker.service";
const axios = require("axios").default;

export class AuthService {
  websocketServerUrl: string | undefined;
  token: string = "";
  decodedToken: any;
  accSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  fragment: string | undefined;
  authorizer: any;
  user: any;
  userSubject = new Subject<any>();
  apiBase: string;
  userSubject$: ReplaySubject<User> = new ReplaySubject<User>();

  gettingUser: boolean = false;
  isSpoofing: boolean = false;
  utils: UtilsService = new UtilsService();
  sensorTracker: SensorTrackerService = new SensorTrackerService();

  constructor() {
    this.apiBase = environment.api;
  }

  init() {
    this.loginWithToken();
  }

  getToken() {
    return new Observable((observer: any) => {
      if (this.token) {
        const token = sessionStorage.getItem("token");
        if (token) {
          observer.next(token);
        } else {
          observer.next(this.token);
        }
      } else {
        this.tokenSubject.subscribe((token: any) => {
          if (token) {
            observer.next(token);
          }
        });
      }
    });
  }

  prosessToken(token: any) {
    this.token = token;
    this.tokenSubject.next(token);
    const decodedToken = jwt_decode(token);
    this.decodedToken = decodedToken;
  }

  refreshUser() {
    this.user = null;
    this.userSubject.next(this.user);
    this.getUser();
  }

  getUser() {
    const id = this.getSNN();
    if (this.user) {
      return new Observable((observer: any) => {
        observer.next(this.user);
        this.updateUserSubject(this.user);
      });
    } else if (this.gettingUser) {
      return this.userSubject.asObservable();
    } else {
      return new Observable((observer: any) => {
        const api = this.apiBase + "/users/" + id;
        this.gettingUser = true;
        axios
          .get(api)
          .then((result: any) => {
            console.log("result", result);

            this.gettingUser = false;
            if (result && this.isSpoofing) result.isSpoofing = true;
            this.user = result;
            this.userSubject.next(result);
            observer.next(result);
          })
          .catch((error: any) => {
            this.gettingUser = false;
            this.utils.handleHttpError(error);
            observer.error("no user", error);
          });
        // });
      });
    }
  }

  getUserSubject() {
    return this.userSubject$;
  }
  updateUserSubject(usr: any) {
    this.user = { ...usr };
    this.userSubject$.next(usr);
  }

  isLoggedIn() {
    console.log("Checking Login Status");
    return new Observable((observer: any) => {
      this.getUser().subscribe(
        (usr: any) => {
          if (usr) {
            observer.next(true);
          }
        },
        (e: any) => {
          console.log("e", e);
          observer.error(e);
        }
      );
    });
  }

  getSNN() {
    return this.decodedToken
      ? this.decodedToken.snn
      : this.user
      ? this.user.uid
      : null;
  }

  loggedIn() {
    return this.tokenSubject;
  }

  logout() {
    this.user = null;
    sessionStorage.clear();
    localStorage.clear();
    const url =
      environment.socket.logoutUrl +
      "?&redirect_uri=" +
      encodeURIComponent(document.location.href);
    window.location.href = url;
  }

  loginForRegister() {
    let observer;
    return Observable.create(async (obs: any) => {
      observer = obs;
      let obj = {
        grant_type: "client_credentials",
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
      };

      axios
        .post(environment.socket.oauthTokenUrl, obj, { validateStatus: false })
        .then((response) => {
          if (!response) {
            observer.next({});
            observer.complete();
          } else if (response.status !== 200) {
            const obj = { msg: response?.data?.error };
            observer.next(obj);
            console.log("Error:", response);
            observer.complete();
          } else {
            this.handleTokenResponse(response);
            observer.next(obj);
            observer.complete();
          }
        })
        .catch((err) => {
          const obj = { msg: err?.data?.error };
          observer.next(obj);
          console.log("Error", err);
        });
    });
  }

  loginUser(email: string, password: string, twoFaCode?: string) {
    let observer;
    return Observable.create(async (obs: any) => {
      observer = obs;
      let obj = {
        grant_type: "password",
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        username: email,
        password: password,
      };

      if (twoFaCode) obj["twoFaCode"] = twoFaCode;
      axios
        .post(environment.socket.oauthTokenUrl, obj, { validateStatus: false })
        .then((response) => {
          if (!response) {
            observer.next({});
            observer.complete();
          } else if (response.status !== 200) {
            const obj = {
              msg: response?.data?.error,
              message: response?.data?.message,
            };
            observer.next(obj);
            console.log("Error:", response);
            observer.complete();
          } else {
            this.handleTokenResponse(response);
            observer.next(obj);
            observer.complete();
          }
        })
        .catch((err) => {
          const obj = {
            msg: err?.data?.error,
            message: err?.data?.message,
          };
          observer.next(obj);
          console.log("Catch Error", err);
        });
    });
  }

  async loginWithToken() {
    const refresh_token = sessionStorage.getItem("refresh_token");

    if (!refresh_token || refresh_token === "undefined") {
      console.log("refresh_token");
      if (!window.location.href.includes("/auth/login-page")) {
        const url2 = "/auth/login-page";
        window.location.href = url2;
      }
      return;
    }

    const obj = {
      grant_type: "refresh_token",
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      refresh_token: refresh_token,
    };

    axios
      .post(environment.socket.oauthTokenUrl, obj)
      .then((response) => {
        this.handleTokenResponse(response);
      })
      .catch((err) => {
        console.log("Error:", err);
        this.logout();
      });
  }

  handleTokenResponse(response: { data: any }) {
    let token = response.data.access_token;
    let refresh_token = response.data.refresh_token;
    if (refresh_token) sessionStorage.setItem("refresh_token", refresh_token);
    if (token) sessionStorage.setItem("token", token);
    let decode = jwt_decode(token);
    if (decode && decode["snn"]) localStorage.setItem("uid", decode["snn"]);
  }
}
