/* eslint-disable no-unused-vars */
import environment from "../../../config";
import firebase from "firebase/app";
import store from "../store";
import {
  updateSecdevDeviceInfo,
  getDeviceMeta,
} from "../../services/device.requests";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

export function setZoneData(zoneData) {
  console.log("zoneData", zoneData);
  return (dispatch) => {
    dispatch(setZone(zoneData));
  };
}

export function setFloorPlan(floorPlan) {
  return (dispatch) => {
    dispatch(setFloorplan(floorPlan));
  };
}

export function fetchZoneData() {
  return (dispatch) => {
    getZoneDataFromSecdevMeta(dispatch);
  };
}

export async function getZoneDataFromSecdevMeta(dispatch) {
  const state = store.getState();
  const snn = state?.eventsReducer?.events?.snn;

  if (!snn) return;
  getDeviceMeta(snn, "secdev:deviceInfo").then((res) => {
    setDeviceMetaToZoneAndFloorPlan(dispatch, res);
  });
}

function setDeviceMetaToZoneAndFloorPlan(dispatch, res) {
  if (res) {
    const zoneCoordinates = res?.zoneCoordinates;
    const zoneNames = res?.zoneNames;
    if (zoneNames || zoneCoordinates) {
      dispatch(setZone({ positions: zoneCoordinates, zones: zoneNames }));
    }
    if (res.floorPlan) {
      dispatch(setFloorplan(res.floorPlan));
    }
  }
}

function setDeviceMetaToZoneData(res) {
  if (res) {
    const zoneCoordinates = res?.zoneCoordinates;
    const zoneNames = res?.zoneNames;
    if (zoneNames || zoneCoordinates) {
      setZoneData({ positions: zoneCoordinates, zones: zoneNames });
    }
  }
}

export async function getZoneDataFromFirebase(dispatch) {
  const dbRef = firebase.database().ref();
  dbRef
    .child("smartsecurity-module/12345678/settings/data")
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        getZoneLayoutDataFromFirebase(dispatch, snapshot.val());
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.log("Error", error);
    });
}

export async function getZoneLayoutDataFromFirebase(dispatch, data) {
  const dbRef = firebase.database().ref();
  dbRef
    .child("smartsecurity-module/12345678/layout/")
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        dispatch(setZoneData({ positions: snapshot.val(), zones: data }));
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.log("Error", error);
    });
}

export async function saveZoneDataToDB(zoneData) {
  if (zoneData.positions) saveZoneLayoutToDeviceMeta(zoneData.positions);
}

export async function saveZoneLayoutToDeviceMeta(positions) {
  const state = store.getState();
  const snn = state?.eventsReducer?.events?.snn;
  if (!snn) return;
  updateSecdevDeviceInfo(snn, { zoneCoordinates: positions }).then((res) => {
    setDeviceMetaToZoneData(res);
  });
}
export async function saveZoneLayoutToFirebase(positions) {
  if (!positions) return;
  positions.forEach((position, idx) => {
    firebase
      .database()
      .ref("smartsecurity-module/12345678/layout/" + idx)
      .set(position);
  });
}

export async function saveZoneAndFloorPlanDataToDB(rooms, zoneCoordinates) {
  saveZoneAndFloorPlanDataToDeviceMeta(rooms, zoneCoordinates);
}

async function saveZoneAndFloorPlanDataToDeviceMeta(rooms, zoneCoordinates) {
  const state = store.getState();
  const snn = state?.eventsReducer?.events?.snn;
  if (!snn) return;
  updateSecdevDeviceInfo(snn, {
    floorPlan: rooms,
    zoneCoordinates: zoneCoordinates,
  }).then((res) => {
    setDeviceMetaToZoneData(res);
    if (res && res.floorPlan) {
      setFloorPlan(res.floorPlan);
    }
  });
}

export function updateViolatedZones(zones) {
  return (dispatch) => {
    dispatch(setViolatedZones(zones));
  };
}

export const setViolatedZones = (content) => ({
  type: "SET_VIOLATED_ZONES",
  payload: content,
});

export const setZone = (content) => ({
  type: "SET_ZONEDATA",
  payload: content,
});
export const setFloorplan = (content) => ({
  type: "SET_FLOORPLAN",
  payload: content,
});
