import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "components/CustomButtons/Button.js";
import environment from "../../config";
import { initCiData } from "../../shared/redux/actions/eventsActions";
import ZoneSelect from "../Components/ZoneSettingSelect";
import Badge from "components/Badge/Badge.js";

import { postPlatformServiceFunction } from "../../shared/services/device.requests";

import PlayArrow from "@material-ui/icons/PlayArrow";
import Stop from "@material-ui/icons/Stop";
// import defaultImage from "assets/img/cam-buttons/arrow-down-left.png";
// let camButtonPath = "assets/img/cam-buttons/";
import arrowDownLeft from "assets/img/cam-buttons/arrow-down-left.png";
import arrowDownRight from "assets/img/cam-buttons/arrow-down-right.png";
import arrowDown from "assets/img/cam-buttons/arrow-down.png";
import arrowLeft from "assets/img/cam-buttons/arrow-left.png";
import arrowRight from "assets/img/cam-buttons/arrow-right.png";
import arrowUpLeft from "assets/img/cam-buttons/arrow-up-left.png";
import arrowUpRight from "assets/img/cam-buttons/arrow-up-right.png";
import arrowUp from "assets/img/cam-buttons/arrow-up.png";
// import iconMovement from "assets/img/cam-buttons/icon-movement.png";
// import iconMute from "assets/img/cam-buttons/icon-mute.png";
// import iconPlay from "assets/img/cam-buttons/icon-play.png";
import iconRecord from "assets/img/cam-buttons/icon-record.png";
// import iconStop from "assets/img/cam-buttons/icon-stop.png";

let peerConnection;
let socket;
let topic = "PlatformService";
let sessionId = "0";
let audioOn = false;
let candidateTimer;
let localCandidates = [];
let offer;
let remoteAudio;
let remoteVideo;
let localStream;
let initialized = false;
let isPtzMoving = false;
let receivedAllCandidates = false;
let nightVisionOn = false;
let lastCandidateSent = false;
let isPtzMotionTracking = false;
let isBusy = false;
let errorCount = 0;
let logMessage = "";

// const camButtonImagePath = "../../assets/img/cam-buttons/";

// const register from "assets/img/cam-buttons/arrow-down-left.png";
// const register from "assets/img/cam-buttons/arrow-down-right.png";
// const register from "assets/img/cam-buttons/arrow-down.png";
// const register from "assets/img/cam-buttons/arrow-left.png";
// const register from "assets/img/cam-buttons/arrow-right.png";
// const register from "assets/img/cam-buttons/arrow-up-left.png";
// const register from "assets/img/cam-buttons/arrow-up-right.png";
// const register from "assets/img/cam-buttons/arrow-up.png";
// const register from "assets/img/cam-buttons/icon-movement.png";
// const register from "assets/img/cam-buttons/icon-mute.png";
// const register from "assets/img/cam-buttons/icon-play.png";
// const register from "assets/img/cam-buttons/icon-record.png";
// const register from "assets/img/cam-buttons/icon-stop.png";
function IPCam(props) {
  const forceUpdate = React.useReducer(() => ({}))[1];
  const [selectedDeviceOptions, setSelectedDeviceOptions] = React.useState(
    null
  );
  // const [receivedAllCandidates, setReceivedAllCandidates] = React.useState(
  //   false
  // );
  // const [isPtzMoving, setIsPtzMoving] = React.useState(false);
  // const [nightVisionOn, setNightVisionOn] = React.useState(false);
  // const [lastCandidateSent, setLastCandidateSent] = React.useState(false);
  // const [isBusy, setIsBusy] = React.useState(false);
  // const [errorCount, setErrorCount] = React.useState(0);
  const [selectedDevice, setSelectedDevice] = React.useState(null);
  const [selectedDeviceData, setSelectedDeviceData] = React.useState(null);

  const setLog = (msg, e = "") => {
    logMessage = msg;
    console.log(msg, e);
    forceUpdate();
  };
  const initConnection = () => {
    setLog("Init Connection");
    remoteAudio = document.getElementById("remoteAudio");
    remoteVideo = document.getElementById("remoteVideo");

    errorCount = 0;
    isBusy = true;
    forceUpdate();
    initWs();
  };

  const createPeerConnection = async (iceServers) => {
    setLog("Creating WebRTC peer connection");
    iceServers = iceServers.filter((x) => !"nat".includes(x.urls.substr(0, 3)));
    console.log("iceServers", iceServers);
    peerConnection = new RTCPeerConnection({
      iceServers,
    });
    console.log("peerConnection");
    if (!peerConnection) return;
    peerConnection.addEventListener("icecandidate", (e) => {
      if (e.candidate) {
        sendCandidate(`a=${e.candidate.candidate}`);
      }
    });

    // add capabilities
    peerConnection.addEventListener("iceconnectionstatechange", (e) => {
      if (peerConnection.iceConnectionState === "connected") {
        setLog("WebRTC Connected!", e);
      }
    });

    peerConnection.addTransceiver("audio", {
      direction: "recvonly",
    });

    peerConnection.addTransceiver("video", {
      direction: "recvonly",
    });

    peerConnection.addEventListener("track", (e) => {
      console.log("e", e);
      if (e.track.kind === "audio") {
        remoteAudio.srcObject = e.streams[0];
      } else if (e.track.kind === "video") {
        remoteVideo.srcObject = e.streams[0];
      }
      console.log("remoteVideo", remoteVideo);
      forceUpdate();
    });

    localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    localStream.getTracks().forEach((x) => {
      console.log("x", x);
      peerConnection.addTrack(x);
    });

    // mute by default
    if (localStream) localStream.getAudioTracks()[0].enabled = audioOn;

    await createOffer();
  };

  const createOffer = async () => {
    if (!offer) {
      offer = await peerConnection.createOffer({
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      });
      await peerConnection.setLocalDescription(offer);
      const sdp = offer.sdp.replace(/\r\na=extmap[^\r\n]*/g, "");
      setLog("Sending offer...");
      sendWSRequest("localOffering", "offer", sdp);
    }
  };

  const sendCandidate = (candidate) => {
    if (candidateTimer) {
      clearTimeout(candidateTimer);
    }
    if (!lastCandidateSent) {
      candidateTimer = setTimeout(() => {
        clearTimeout(candidateTimer);
        // setLastCandidateSent(true);
        lastCandidateSent = true;
        setLog("Candidate generation complete");
        localCandidates.push("");
        sendWSRequest("localCandidate", "candidate", localCandidates);
        return;
      }, 1000);
    }
    candidate = candidate ? candidate : "";
    setLog(`${new Date()}: Sending candidate...`, candidate);
    localCandidates.push(candidate);
  };

  const sendWSRequest = (subTopic, type, payload) => {
    setLog(`Sending ${type} command`);
    payload = payload || "";
    socket.send(
      JSON.stringify({
        topic: `${topic}:${subTopic}`,
        content: {
          sessionId,
          snn: selectedDevice,
          type,
          payload,
        },
      })
    );
  };

  const initWs = () => {
    const base = environment.base.replace("https://", "");
    let wsUri = `wss://${base}/websocket-server/ws`;
    // wsUri = "ws://localhost:3009/websocket-server/ws";
    socket = new WebSocket(wsUri);
    const jwtToken = sessionStorage.getItem("token");

    // if (!jwtToken) return;
    socket.onopen = (e) => {
      websocketAuthInit(e, jwtToken);
    };

    socket.onerror = (e) => {
      setLog("ERROR", e);
    };

    socket.onmessage = async (msg) => {
      const m = JSON.parse(msg.data);
      if (m.error !== undefined) await handleSocketErrorMessage(m);

      if (m.topic === "authorization-reply") {
        handleSocketAuthReply(setLog, sendWSRequest);
      } else {
        await handleSocketMessageContent(m);
      }
    };
    socket.onclose = (e) => {
      setLog("closed", e);
      // resetConnectionVariables();
    };
  };

  const toggleNightVisionMode = () => {
    postPlatformServiceFunction(
      selectedDevice,
      nightVisionOn ? "1" : "2",
      "snn:ci:meta:fx:10"
    );
    nightVisionOn = !nightVisionOn;
  };

  const toggleMotionTracking = () => {
    postPlatformServiceFunction(
      selectedDevice,
      isPtzMotionTracking ? "false" : "true",
      "snn:ci:meta:fx:20"
    );
    isPtzMotionTracking = !isPtzMotionTracking;
    console.log("isPtzMotionTracking", isPtzMotionTracking);
  };

  const muteToggle = () => {
    setLog("Mute/Unmute button clicked");
    audioOn = !audioOn;
    if (localStream) localStream.getAudioTracks()[0].enabled = audioOn;
    setLog(`Audio muted: ${audioOn}`);
  };
  const stopStreaming = () => {
    // setIsBusy(false);
    isBusy = false;
    remoteVideo.srcObject = null;
    remoteAudio.srcObject = null;
    sendWSRequest("getObjectStream", "ice", "disconnect");
    removeAudio();
  };

  const removeAudio = () => {
    if (!localStream) return;
    localStream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const handleDeviceSelect = (index, givenSnns) => {
    if(peerConnection) stopStreaming();
    
    const selectedDeviceSnn = givenSnns
      ? givenSnns[index]
      : selectedDeviceOptions[index];
    setSelectedDevice(selectedDeviceSnn);

    let selectedDeviceData = null;
    props.ciDevices.find((container) => {
      selectedDeviceData = container.objects.find(
        (object) => object.snn === selectedDeviceSnn
      );
      return selectedDeviceData;
    });
    console.log("selectedDeviceData", selectedDeviceData);
    setSelectedDeviceData(selectedDeviceData);
  };

  if (!selectedDeviceOptions && props.ciDevices) {
    const devices = props.ciDevices.map((container) =>
      container.objects.map((object) => object.snn)
    );
    if (devices) {
      const snns = devices.flat();
      setSelectedDeviceOptions(snns);
      handleDeviceSelect(0, snns);
    }
  }

  const onInit = () => {
    if (!props.ciDevices) props.initCiData();
  };

  if (!initialized) {
    initialized = true;
    onInit();
  }

  const deviceButtons = () => {
    if (!peerConnection) return;
    return (
      <>
        <br />
        <Button
          color="warning"
          onClick={() => {
            muteToggle();
          }}
        >
          Toggle Microphone
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggleNightVisionMode();
          }}
        >
          Toggle NightVision
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggleMotionTracking();
          }}
        >
          Motion Tracking
        </Button>
        {deviceMoveButtons()}
      </>
    );
  };

  const camMoveButton = (conf) => {
    return (
      <button
        className="ptz"
        id={conf.id}
        onMouseDown={(event) => {
          event.stopPropagation();
          console.log("down", conf.command);
          if (conf.command) sendMovementCommand(conf.command);
        }}
        onMouseUp={(event) => {
          event.stopPropagation();
          console.log("");
          console.log("up");

          stopMovementCommand();
        }}
      >
        <img src={conf.src} alt={conf.alt} />
      </button>
    );
  };
  const deviceMoveButtons = () => {
    if (!peerConnection) return;
    return (
      <>
        <br />
        <table>
          <tbody>
            <tr>
              <td>
                {camMoveButton({
                  id: "btnPtzUpLeft",
                  src: arrowUpLeft,
                  alt: "arrowUpLeft",
                  command: 7,
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "btnPtzUp",
                  src: arrowUp,
                  alt: "arrowUp",
                  command: 0,
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "btnPtzUpRight",
                  src: arrowUpRight,
                  alt: "arrowUpRight",
                  command: 1,
                })}
              </td>
            </tr>
            <tr>
              <td>
                {camMoveButton({
                  id: "btnPtzLeft",
                  src: arrowLeft,
                  alt: "arrowLeft",
                  command: 6,
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "",
                  src: iconRecord,
                  alt: "iconRecord",
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "btnPtzRight",
                  src: arrowRight,
                  alt: "arrowRight",
                  command: 2,
                })}
              </td>
            </tr>
            <tr>
              <td>
                {camMoveButton({
                  id: "btnPtzDownLeft",
                  src: arrowDownLeft,
                  alt: "arrowDownLeft",
                  command: 5,
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "btnPtzDown",
                  src: arrowDown,
                  alt: "arrowDown",
                  command: 4,
                })}
              </td>
              <td>
                {camMoveButton({
                  id: "btnPtzDownRight",
                  src: arrowDownRight,
                  alt: "arrowDownRight",
                  command: 3,
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  function sendMovementCommand(value) {
    if (!peerConnection) return;
    postPlatformServiceFunction(selectedDevice, value, "snn:ci:meta:fx:18");
    isPtzMoving = true;
    console.log("isPtzMoving", isPtzMoving);
  }

  function stopMovementCommand() {
    console.log("isPtzMoving", isPtzMoving);
    // if (!peerConnection) return;
    if (!isPtzMoving) return;
    console.log("Stop PTZ");
    postPlatformServiceFunction(selectedDevice, "true", "snn:ci:meta:fx:19");
    isPtzMoving = false;
  }

  const badgeIndicators = () => {
    const stateColor =
      selectedDeviceData?.state === "ACTIVE" ? "success" : "danger";
    const lastStateColor =
      selectedDeviceData?.shadow?.lastState === "ONLINE" ? "success" : "danger";
    return (
      <>
        <Badge color={lastStateColor}>
          {selectedDeviceData?.shadow?.lastState}
        </Badge>
        <Badge color={stateColor}>{selectedDeviceData?.state} </Badge>
      </>
    );
  };
  if (!selectedDeviceOptions || selectedDeviceOptions?.length === 0)
    return <></>;
  return (
    <div style={{ marginTop: " -70px" }}>
      <ZoneSelect
        style={{ width: "100%" }}
        label="Selected Device"
        options={selectedDeviceOptions}
        selectionChange={handleDeviceSelect}
      />
      <br />
      <br />
      <p>Name: {selectedDeviceData?.name}</p>
      {badgeIndicators()}

      {/* {deviceMoveButtons()} */}
      <br />
      {!isBusy ? (
        <>
          <Button
            disabled={!selectedDevice}
            color="primary"
            onClick={() => {
              initConnection();
            }}
          >
            Start <PlayArrow />
          </Button>
        </>
      ) : (
        <>
          <Button
            color="danger"
            id="btnEndStream"
            onClick={() => {
              stopStreaming();
            }}
          >
            End Stream <Stop />
          </Button>
          <span> Status: {logMessage}</span>
          {deviceButtons()}
        </>
      )}
      <br />
      <div id="container">
        <video
          className="ipVideo"
          id="remoteVideo"
          playsInline
          autoPlay
        ></video>
        <audio id="remoteAudio" autoPlay></audio>
      </div>
    </div>
  );

  function websocketAuthInit(e, jwtToken) {
    setLog("Connection open", e);
    socket.send(
      JSON.stringify({
        topic: "authorization",
        content: jwtToken,
      })
    );
  }

  async function handleSocketMessageContent(m) {
    if (m.content) {
      if (m.content.result.type === "ice") {
        await handleSocketIceList(m);
      } else if (m.content.result.type === "complete") {
        if (!receivedAllCandidates) {
          // setReceivedAllCandidates(true);
          receivedAllCandidates = true;
          console.log("Adding answer:");
          console.log(m.content.result.payload.remoteAnswer[0]);

          await handleSocketAnswer(m);
          if (m.content.result.payload.remoteCandidate.length === 0) {
            throw new Error("Remote candidates empty!");
          }
          m.content.result.payload.remoteCandidate.forEach(async (c) => {
            console.log(`Adding candidate: ${c}`);
            await peerConnection
              .addIceCandidate(
                new RTCIceCandidate({
                  candidate: c,
                  sdpMid: "0",
                  sdpMLineIndex: 0,
                })
              )
              .catch((e) => {
                console.log(e);
                console.log("addIceCandidate failed: " + e.name);
              });
          });
        }
      } else if (m.content.result.type === "answer") {
        // ignore ws answer
        // await handleSocketAnswer(m);
      } else if (m.content.result.type === "candidate") {
        handleSocketCandidate(m);
      } else if (m.content.result.type === "disconnect") {
        await handleSocketDisconnect();
      }
    }
  }

  async function handleSocketIceList(m) {
    setLog("Got an ice server list!");
    sessionId = m.content.result.sessionId;
    setLog(`Session ID: ${sessionId}`);
    await createPeerConnection(m.content.result.payload);
  }

  async function handleSocketAnswer(m) {
    setLog("Got an answer!");
    const answer = {
      type: "answer",
      sdp: m.content.result.payload.remoteAnswer[0].sdp,
    };
    await peerConnection.setRemoteDescription(answer);
  }

  function handleSocketCandidate(m) {
    if (Array.isArray(m.content.result.payload) && !receivedAllCandidates) {
      // setReceivedAllCandidates(true);
      receivedAllCandidates = true;
      m.content.result.payload.forEach(async (x) => {
        setLog(`Adding candidate: ${x}`);
        await peerConnection
          .addIceCandidate(
            new RTCIceCandidate({
              candidate: x,
              sdpMid: "0",
              sdpMLineIndex: 0,
            })
          )
          .catch((e) => {
            console.log(e);
            setLog("addIceCandidate failed: " + e.name);
          });
      });
    }
  }

  async function handleSocketDisconnect() {
    setLog("Got a disconnect!");
    await peerConnection.close();
    resetConnectionVariables();

    if (isBusy) {
      if (errorCount < 3) {
        // setErrorCount(errorCount + 1);
        errorCount = errorCount + 1;
        setLog("Restarting session");
        sendWSRequest("getObjectStream", "ice");
      } else {
        alert("It's obviously not working");
      }
    }
  }

  function resetConnectionVariables() {
    if (peerConnection) {
      peerConnection.close();
      peerConnection = null;
    }
    sessionId = "0";
    candidateTimer = null;
    // setLastCandidateSent(false);
    lastCandidateSent = false;
    // setReceivedAllCandidates(false);
    receivedAllCandidates = false;
    localCandidates = [];
    offer = null;
    localStream = null;
    // setIsBusy(false);
    isBusy = false;
    forceUpdate();
  }

  async function handleSocketErrorMessage(m) {
    console.log("isBusy m.error", isBusy, m.error);

    resetConnectionVariables();
    if (isBusy) {
      if (errorCount < 3) {
        // setErrorCount(errorCount + 1);
        errorCount = errorCount + 1;
        setLog("Restarting session");
        sendWSRequest("getObjectStream", "ice");
      } else {
        alert("It's obviously not working");
      }
    }
  }
}

IPCam.propTypes = {
  queryData: PropTypes.any,
  ciDevices: PropTypes.any,
  initCiData: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    queryData: state.eventsReducer.queryData,
    ciDevices: state.eventsReducer.ciDevices,
  };
};

export default connect(mapStateToProps, { initCiData })(IPCam);
function handleSocketAuthReply(setLog, sendWSRequest) {
  setLog("Auth reply");
  sendWSRequest("getObjectStream", "ice");
}
