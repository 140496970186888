import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { requestOtp, sendOtp } from "../../shared/services/register.requests";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { connect } from "react-redux";

import { setEvents } from "../../shared/redux/actions/eventsActions";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";

const useStyles = makeStyles(styles);

function MobileVerify(props) {
  let initTitle = "Verify Cell Number";
  let initText =
    "We need to verify your cell number. This will allow you to view your appointments and manage your devices.";
  if (props.showVerifyNum === false) {
    initTitle = "Verify OTP";
    initText =
      "Awsome! We have sent you a One Time Pin via SMS. Please confim this OTP in the text field below.";
  }

  const [showVerifyNum, setShowVerifyNum] = React.useState(props.showVerifyNum);
  const [formTitle, setFormTitle] = React.useState(initTitle);
  const [formText, setFormText] = React.useState(initText);

  const [responseMessage, setResponseMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [registerCellNoState, setregisterCellNoState] = React.useState("");
  const [otpState, setOtpState] = React.useState("");
  const [registerCellNo, setregisterCellNo] = React.useState(props.mobileNo);
  const [otp, setOtp] = React.useState(0);

  const classes = useStyles();

  const registerOtp = () => {
    if (otpState === "" || otpState === "error") {
      setOtpState("error");
      return;
    }
    let number = registerCellNo;
    if (number.startsWith("0")) {
      number = number.substring(1);
      number = "+27" + number;
    }
    console.log("number", number);
    console.log("otpState", otpState);
    console.log("otp", otp);
    setLoading(true);
    sendOtp(Number(otp), number).then((res) => {
      setLoading(false);
      if (res?.data === "") {
        localStorage.removeItem(props.user.snn + "otpRequested");
        const url2 = "/admin/dashboard";
        window.location.href = url2;
      } else {
        console.log("res", res);
      }
    });
  };

  const submitForm = (event) => {
    if (event) event.preventDefault();
    if (showVerifyNum) {
      registerNumber();
    } else {
      registerOtp();
    }
  };
  const registerNumber = () => {
    if (verifyNumber(registerCellNo)) {
      setregisterCellNoState("success");
    } else {
      setregisterCellNoState("error");
      return;
    }

    let number = registerCellNo;
    if (number.startsWith("0")) {
      number = number.substring(1);
      number = "+27" + number;
    }
    setLoading(true);
    requestOtp(number).then((res) => {
      setLoading(false);
      if (res) {
        setShowVerifyNum(false);
        setFormTitle("Verify OTP");
        setFormText(
          "Awsome! We have sent you a One Time Pin via SMS. Please confim this OTP in the text field below."
        );
        localStorage.setItem(props.user.snn + "otpRequested", true);
        let optInputElement = document.getElementById("otp-input");
        optInputElement.value = "";
        optInputElement.focus();
      } else {
        console.log("res", res);
      }
    });
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    if (verifyLength(value, 10) && !verifyLength(value, 13)) {
      return true;
    }
    return false;
  };
  return (
    <div id="mobile-verify-wrapper">
      <GridContainer justify="center">
        <GridItem xs={6} sm={6} md={6}>
          <form onSubmit={(event) => submitForm(event)}>
            <Card>
              <CardHeader>
                <h3 className={classes.cardTitle}>{formTitle}</h3>
              </CardHeader>
              <CardBody>
                <p className={classes.cardCategory}>{formText}</p>
                {showVerifyNum ? (
                  <CustomInput
                    success={registerCellNoState === "success"}
                    error={registerCellNoState === "error"}
                    labelText="Cell Number"
                    id="cell-number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      defaultValue: registerCellNo,
                      onChange: (event) => {
                        if (verifyNumber(event.target.value)) {
                          setregisterCellNoState("success");
                        } else {
                          setregisterCellNoState("error");
                        }
                        setregisterCellNo(event.target.value);
                      },
                      type: "text",
                    }}
                  />
                ) : (
                  <CustomInput
                    success={otpState === "success"}
                    error={otpState === "error"}
                    labelText="Otp"
                    id="otp-input"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        if (verifyLength(event.target.value, 5)) {
                          setOtpState("success");
                        } else {
                          setOtpState("error");
                        }
                        setOtp(event.target.value);
                      },
                      type: "number",
                    }}
                  />
                )}
              </CardBody>
              <CardFooter
                className={classes.justifyContentCenter}
                style={{ flexDirection: "column" }}
              >
                {responseMessage ? (
                  <span
                    onClick={() => {
                      setResponseMessage(null);
                    }}
                  >
                    <SnackbarContent
                      message={responseMessage}
                      color="warning"
                      close
                    />
                  </span>
                ) : null}
                <Button color="warning" type="submit">
                  {loading ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    <>Submit</>
                  )}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

MobileVerify.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.bool,
  setEvents: PropTypes.func,
  mobileNo: PropTypes.string,
  showVerifyNum: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    loading: state.eventsReducer.loading,
  };
};

export default connect(mapStateToProps, { setEvents })(MobileVerify);
