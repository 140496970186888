/* eslint-disable no-unused-vars */
import environment from "../../../config";
import firebase from "firebase/app";
import {
  updateSecdevDeviceInfo,
  getDeviceMeta,
} from "../../services/device.requests";
import store from "../store";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

firebase.initializeApp(environment.firebase);

export function setFloorPlan(floorPlan) {
  return (dispatch) => {
    dispatch(setFloorplan(floorPlan));
  };
}

export function fetchFloorplanData() {
  return (dispatch) => {
    getFloorplanDataFromSecdevMeta(dispatch);
  };
}
export async function getFloorplanDataFromSecdevMeta(dispatch) {
  const state = store.getState();
  const snn = state?.eventsReducer?.events?.snn;
  if (!snn) return;
  getDeviceMeta(snn, "secdev:deviceInfo").then((res) => {
    if (res && res.floorPlan) {
      dispatch(setFloorPlan(res.floorPlan));
    }
  });
}

export async function getFloorplanDataFromFirebase(dispatch) {
  const dbRef = firebase.database().ref();
  dbRef
    .child("smartsecurity-module/12345678/floorplan-layout/")
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        dispatch(setFloorPlan(snapshot.val()));
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.log("Error", error);
    });
}

export async function saveFloorplanDataToDB(rooms) {
  saveFloorplanDataToDeviceMeta(rooms);
}

export async function saveFloorplanDataToDeviceMeta(rooms) {
  const state = store.getState();
  const snn = state?.eventsReducer?.events?.snn;
  if (!snn) return;
  updateSecdevDeviceInfo(snn, { floorPlan: rooms }).then((res) => {
    if (res && res.floorPlan) {
      setFloorPlan(res.floorPlan);
    }
  });
}

export async function saveFloorplanDataToFirebase(rooms) {
  if (!rooms) return;
  firebase
    .database()
    .ref("smartsecurity-module/12345678/floorplan-layout/")
    .set({});
  rooms.forEach((room, idx) => {
    firebase
      .database()
      .ref("smartsecurity-module/12345678/floorplan-layout/" + idx)
      .set(room);
  });
}

export const setFloorplan = (content) => ({
  type: "SET_FLOORPLAN",
  payload: content,
});
