import React from "react";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { setEvents } from "../../shared/redux/actions/eventsActions";
import ZoneSelect from "./ZoneSettingSelect";
import CustomInput from "components/CustomInput/CustomInput.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const useStyles = makeStyles(styles);

function ZoneSettings(props) {
  const roomTypes = [
    "Lounge",
    "Bedroom",
    "Hallway",
    "kitchen",
    "Roof",
    "Garage",
    "Other",
  ];
  const sensorTypes = ["PANIC", "WINDOW", "DOOR", "PIR", "BEAM", "OTHER"];
  const zoneData = props?.zoneData;
  const zoneId = zoneData?.id ? zoneData?.id + "" : "0";

  const zoneRoomType =
    zoneData?.zoneRoomType || zoneData?.zoneRoomType === 0
      ? zoneData?.zoneRoomType
      : roomTypes.length - 1;

  const zoneSensorType =
    zoneData?.zoneType || zoneData?.zoneType === 0
      ? zoneData?.zoneType
      : sensorTypes.length - 1;

  const [hidden, setHidden] = React.useState(zoneData?.hidden);
  const [dirty, setDirty] = React.useState(false);
  const [roomTypeSelect, setRoomTypeSelect] = React.useState(zoneRoomType);
  const [sensorTypeSelect, setSensorTypeSelect] = React.useState(
    zoneSensorType
  );
  const n = props?.zoneData?.name ? props?.zoneData?.name + "" : "";
  const [name, setName] = React.useState(n);

  const submitChanges = () => {
    const zone = {
      id: zoneId,
      zoneName: name,
      zoneRoomType:
        roomTypeSelect === roomTypes.length - 1 ? undefined : roomTypeSelect,
      zoneType:
        sensorTypeSelect === sensorTypes.length - 1
          ? undefined
          : sensorTypeSelect,
      zoneHidden: hidden,
    };

    hide();
    props.updateZoneData(zone);
  };

  const classes = useStyles();

  const hide = () => {
    props.setModalActive(false);
  };

  const handleRoomTypeSelect = (index) => {
    setDirty(true);
    setRoomTypeSelect(index);
  };
  const handleSensorTypeSelect = (index) => {
    setDirty(true);
    setSensorTypeSelect(index);
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={props.showModal}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Zone Settings</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <span key="key">
            <div>Zone No: {zoneId}</div>
            <FormControlLabel
              label="Zone hidden"
              control={
                <Switch
                  checked={hidden}
                  onChange={(event) => {
                    setHidden(event.target.checked);
                    setDirty(true);
                  }}
                />
              }
            />

            <CustomInput
              labelText="Zone Name"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                defaultValue: name,
                onChange: (event) => {
                  setDirty(true);
                  setName(event.target.value);
                },
              }}
            />
            <br />
          </span>
          <ZoneSelect
            selectIndex={roomTypeSelect}
            label="Room Type"
            options={roomTypes}
            selectionChange={handleRoomTypeSelect}
          />
          <span> </span>
          <ZoneSelect
            selectIndex={sensorTypeSelect}
            label="Sensor Type"
            options={sensorTypes}
            selectionChange={handleSensorTypeSelect}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center" }}
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button color="danger" onClick={() => hide()} round>
            <Close className={classes.modalClose} />
          </Button>
          <Button
            disabled={!dirty}
            onClick={() => {
              submitChanges();
            }}
            color="warning"
            round
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ZoneSettings.propTypes = {
  day: PropTypes.array,
  updateDay: PropTypes.func,
  events: PropTypes.any,
  setEvents: PropTypes.func,
  showModal: PropTypes.bool,
  setModalActive: PropTypes.func,
  zoneData: PropTypes.any,
  updateZoneData: PropTypes.func,
  zoneRoomType: PropTypes.number,
  zoneType: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    events: state.eventsReducer.events,
  };
};

export default connect(mapStateToProps, { setEvents })(ZoneSettings);
