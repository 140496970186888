import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import DeviceHub from "@material-ui/icons/DeviceHub";
import Warning from "@material-ui/icons/Warning";
import Update from "@material-ui/icons/Update";
import DateRange from "@material-ui/icons/DateRange";
import AvTimer from "@material-ui/icons/AvTimer";
import LocalOffer from "@material-ui/icons/LocalOffer";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import PieChartIcon from "@material-ui/icons/PieChart";
import EvStationIcon from "@material-ui/icons/EvStation";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GeyserSvg from "../Components/GeyserSvg.js";
import DeviceButtons from "../Components/DeviceButtons.js";
import { connect } from "react-redux";

import { setEvents } from "../../shared/redux/actions/eventsActions";
import {
  internalTempChart,
  ambientTempChart,
  externalTempChart,
  energyDataChart,
  heatingTimeChart,
  dailyEnergyUsedPieChart,
} from "variables/deviceCharts";

import { UtilsService } from "../../shared/services/utils.service.ts";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const thermostat = require("../../assets/img/icons/thermostat-white-18dp.svg");
const temperature_white = require("../../assets/img/icons/water-temperature-white.png");

require("chartist-plugin-tooltips");
require("chartist-plugin-legend");
require("chartist");

const useStyles = makeStyles(styles);
const utilServices = new UtilsService();
function Charts(props) {
  const smallChartLabelFrequesncy = 120;
  utilServices.initChartData(
    externalTempChart,
    props,
    smallChartLabelFrequesncy
  );
  utilServices.initChartData(
    ambientTempChart,
    props,
    smallChartLabelFrequesncy
  );
  utilServices.initChartData(
    internalTempChart,
    props,
    smallChartLabelFrequesncy
  );
  
  utilServices.initChartData(heatingTimeChart, props);
  utilServices.initChartData(energyDataChart, props);
  utilServices.initChartData(dailyEnergyUsedPieChart, props);

  const classes = useStyles();

  const getTimePeriodString = (props) => {
    if (props && props.eventsQueryData) {
      const start = props.eventsQueryData.startTs;
      const end = props.eventsQueryData.endTs;
      const duration = end - start;

      const sixDays = 518400000;
      const sevenDays = 604800000;
      if (duration > sixDays && duration <= sevenDays) {
        return "week";
      }
      return "period";
    }
    return "week";
  };

  const timePeriod = getTimePeriodString(props);
  const deviceState = props?.events?.state;
  const waterTemp = deviceState
    ? deviceState.waterTemperatureInternal + "°"
    : "0";

  const getSeriesAverage = (seriesProp) => {
    const data = utilServices.getChartData(props, seriesProp);
    if (!data) return "";

    const series = data?.series[0];
    const { length } = series;
    const sumOfSeries = series
      .map((item) => {
        return item.value;
      })
      .reduce((a, b) => a + b, 0);
    return (sumOfSeries / length).toFixed(2);
  };
  const getSeriesChange = (seriesProp) => {
    const data = utilServices.getChartData(props, seriesProp);
    if (!data) return;
    const series = data?.series[0];
    const firstItem = JSON.parse(JSON.stringify(series))[0];
    const lastItem = series[series.length - 1];
    return lastItem?.value - firstItem?.value;
  };
  const getHeatingTimeString = (timeInSeconds) => {
    if (!timeInSeconds) return "0 Min";
    const hours = Math.floor(timeInSeconds / 60 / 60);
    const hoursAsSeconds = hours * 60 * 60;
    const remainingSeconds = timeInSeconds - hoursAsSeconds;
    const minutes = Math.floor(remainingSeconds / 60);
    return hours + " H " + minutes + " Min";
  };
  const usedEnergy = getSeriesChange("ene");
  const usedEnergyCost = usedEnergy
    ? ((usedEnergy / 1000) * props.kwPrice).toFixed(2)
    : 0;
  const usedEnergyCostString =
    usedEnergy || usedEnergy === 0 ? "R " + usedEnergyCost : "R 0";
  const usedEnergyString = usedEnergy ? "(" + usedEnergy / 1000 + " Kwh)" : "";
  const heatingTime = getSeriesChange("het");
  const heatingTimeString = getHeatingTimeString(heatingTime);
  const averageAmbientTemp = getSeriesAverage("amb");
  const ambientTempString =
    averageAmbientTemp && !isNaN(averageAmbientTemp)
      ? averageAmbientTemp + "°"
      : "0";
  return (
    <div>
      <DeviceButtons />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <DeviceHub />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>My Device</h4>
            </CardHeader>
            <CardBody>
              <NavLink to={`/admin/device`}>
                <GeyserSvg height={"70vh"} />
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Charts.propTypes = {
  user: PropTypes.any,
  events: PropTypes.any,
  kwPrice: PropTypes.number,
  eventsQueryData: PropTypes.any,
  loading: PropTypes.bool,
  setEvents: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    events: state.eventsReducer.events,
    kwPrice: state.priceReducer.pricePerKilowatt,
    eventsQueryData: state.eventsReducer.queryData,
    loading: state.eventsReducer.loading,
  };
};

export default connect(mapStateToProps, { setEvents })(Charts);
