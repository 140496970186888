/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GeyserSvg from "../Components/GeyserSvg"

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { connect } from 'react-redux'

const useStyles = makeStyles(styles);

function GeyserPage(props) {
  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center" >
        <GridItem xs={12} sm={12} md={10}>
          <Card chart >
            <CardHeader >
              <GeyserSvg />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>My Device</h4>
              <p className={classes.cardCategory}>Serial: {props?.events?.serial}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
     </div>
  );
}

const mapStateToProps = state => {
  return { user: state.userReducer.user, events: state.eventsReducer.events  }
}

export default connect(
  mapStateToProps
)(GeyserPage);