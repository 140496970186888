import React from "react";
import { connect } from "react-redux";
import { UtilsService } from "../../shared/services/utils.service";

const utils = new UtilsService();

const styles = {
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
};

const initGraphic = (reported) => {
  const svgGraphic = document.getElementById("device-svg");
  if (!svgGraphic) return;

  svgGraphic.querySelector("#External_Temp_Info #Green_Badge tspan").innerHTML =
    utils.reportedRounded("waterTemperatureExternal", reported) + "°C";

  svgGraphic.querySelector(
    "#Internal_Temp_Info #Group-2-Copy-4 tspan"
  ).innerHTML =
    utils.reportedRounded("waterTemperatureInternal", reported) + "°C";

  svgGraphic.querySelector("#Ambient_Temp_Info #Green_Badge tspan").innerHTML =
    utils.reportedRounded("ambientTemperature", reported) + "°C";

  svgGraphic.querySelector(
    "#RSSI_Info #Group-2-Copy-5 tspan"
  ).innerHTML = utils.reportedRounded("signalRssi", reported);

  svgGraphic.querySelector("#Mains_Info #Group-2-Copy-4 tspan").innerHTML =
    utils.reportedRounded("mainsVoltage", reported) + "V";

  svgGraphic.querySelector("#Mains_Info #Group-2-Copy-4 tspan").innerHTML =
    utils.reportedRounded("mainsVoltage", reported) + "V";

  svgGraphic.querySelector(
    "#Master_Disable_Fault"
  ).style.display = !utils.getBoolean("masterDisable", reported)
    ? "none"
    : "block";

  const clr = reported && reported.signalRssi ? reported.signalRssi : null;

  if (clr) {
    svgGraphic
      .querySelector("#RSSI_Colour")
      .setAttribute("fill", utils.rssiToColor2(clr));
  }

  // Internal_Temp_Colour
  const clr1 = "#6BAF1D";
  const clr2 = "#D0021B";

  svgGraphic
    .querySelector("#Water_Valve_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultWaterValveBoth0", reported) &&
        !utils.getBoolean("faultWaterValveBoth1", reported) &&
        !utils.getBoolean("faultValveRemoved", reported) &&
        !utils.getBoolean("faultValveDoesntOpen", reported) &&
        !utils.getBoolean("faultValveDoesntClose", reported)
        ? clr1
        : clr2
    );

  svgGraphic
    .querySelector("#Internal_Temp_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultOverTemperature", reported) &&
        !utils.getBoolean("faultIntTempProbeDisconnected", reported)
        ? clr1
        : clr2
    );

  svgGraphic
    .querySelector("#Drip_Tray_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultDripTray", reported) &&
        !utils.getBoolean("faultDripTrayProbeRemoved", reported)
        ? clr1
        : clr2
    );

  svgGraphic
    .querySelector("#Element_Open_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultElementOpen", reported) &&
        !utils.getBoolean("faultElementDrawingCurrent", reported)
        ? clr1
        : clr2
    );

  svgGraphic
    .querySelector("#External_Temp_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultExtTempProbeDisconnected", reported) ? clr1 : clr2
    );

  svgGraphic
    .querySelector("#Mains_Colour")
    .setAttribute(
      "fill",
      !utils.getBoolean("faultMainsFailure", reported) &&
        !utils.getBoolean("faultBatteryFailure", reported)
        ? clr1
        : clr2
    );
};

function GeyserSvg(props) {
  const ambientTempVal = () => {
    return "30°C";
  };
  const rssiVal = () => {
    return "21";
  };
  const voltageVal = () => {
    return "220V";
  };
  const internalTemp = () => {
    return "54";
  };
  const externalTemp = () => {
    return "50°C";
  };

  // eslint-disable-next-line react/prop-types
  const reported = props?.events?.state;
  if (reported) {
    setTimeout(() => {
      initGraphic(reported);
    }, 100);
  }

  return (
    <div>
      {reported?.holiday ? (
        <div className="holiday-mode-indicator">Device Off</div>
      ) : null}
      <svg
        id="device-svg"
        style={{ width: "100%" }}
        viewBox="0 0 1203 765"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title></title>
        <desc></desc>
        <defs>
          <rect
            id="path-1"
            x="0"
            y="10"
            width="228.291845"
            height="47"
            rx="4"
          ></rect>
          <filter
            x="-7.9%"
            y="-34.0%"
            width="115.8%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-24.7%"
            y="-23.2%"
            width="149.3%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect
            id="path-4"
            x="0"
            y="10"
            width="228.291845"
            height="47"
            rx="4"
          ></rect>
          <filter
            x="-7.9%"
            y="-34.0%"
            width="115.8%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-5"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-24.7%"
            y="-23.2%"
            width="149.3%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-6"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-7" x="0" y="0" width="194" height="47" rx="4"></rect>
          <filter
            x="-9.3%"
            y="-34.0%"
            width="118.6%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-8"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-24.7%"
            y="-23.2%"
            width="149.3%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-9"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect
            id="path-10"
            x="0"
            y="0"
            width="138.291845"
            height="47"
            rx="4"
          ></rect>
          <filter
            x="-13.0%"
            y="-34.0%"
            width="126.0%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-11"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-24.7%"
            y="-23.2%"
            width="149.3%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-12"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-13" x="0" y="0" width="112" height="47" rx="4"></rect>
          <filter
            x="-16.1%"
            y="-34.0%"
            width="132.1%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-14"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-24.7%"
            y="-23.2%"
            width="149.3%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-15"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-16" x="0" y="0" width="152" height="47" rx="4"></rect>
          <filter
            x="-11.8%"
            y="-34.0%"
            width="123.7%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-17"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-26.1%"
            y="-23.2%"
            width="152.2%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-18"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-19" x="0" y="0" width="150" height="47" rx="4"></rect>
          <filter
            x="-8.5%"
            y="-34.0%"
            width="117.1%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-20"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-26.1%"
            y="-23.2%"
            width="152.2%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-21"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-22" x="0" y="0" width="181" height="47" rx="4"></rect>
          <filter
            x="-9.9%"
            y="-34.0%"
            width="119.9%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-23"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-26.1%"
            y="-23.2%"
            width="152.2%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-24"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <rect id="path-25" x="0" y="0" width="216" height="47" rx="4"></rect>
          <filter
            x="-8.3%"
            y="-34.0%"
            width="116.7%"
            height="176.6%"
            filterUnits="objectBoundingBox"
            id="filter-26"
          >
            <feMorphology
              radius="2"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-26.1%"
            y="-23.2%"
            width="152.2%"
            height="152.2%"
            filterUnits="objectBoundingBox"
            id="filter-27"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g
          id="background"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="roof"
            opacity="0.6"
            transform="translate(-129.000000, -198.000000)"
            fill="#E3E3E3"
            fillRule="nonzero"
          >
            <g id="roof-left" opacity="0.399609375">
              <polygon
                id="Rectangle-5"
                transform="translate(289.399009, 138.101378) rotate(-210.000000) translate(-289.399009, -138.101378) "
                points="59.5828023 120.50579 519.215216 120.50579 533.569577 155.696966 45.2284422 155.696966"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-2"
                transform="translate(289.399009, 207.017431) rotate(-210.000000) translate(-289.399009, -207.017431) "
                points="31.4721804 189.421843 547.325838 189.421843 561.680198 224.613019 17.1178203 224.613019"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-3"
                transform="translate(289.399009, 270.068288) rotate(-210.000000) translate(-289.399009, -270.068288) "
                points="5.75395187 252.4727 573.044067 252.4727 587.398427 287.663876 -8.60040825 287.663876"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-4"
                transform="translate(289.399009, 331.652846) rotate(-210.000000) translate(-289.399009, -331.652846) "
                points="-19.3661783 314.057258 598.164197 314.057258 612.518557 349.248434 -33.7205385 349.248434"
              ></polygon>
            </g>
            <g
              id="roof-right"
              opacity="0.399609375"
              transform="translate(1289.500000, 254.500000) scale(-1, 1) translate(-1289.500000, -254.500000) translate(1000.000000, 0.000000)"
            >
              <polygon
                id="Rectangle-5"
                transform="translate(289.399009, 138.101378) rotate(-210.000000) translate(-289.399009, -138.101378) "
                points="59.5828023 120.50579 519.215216 120.50579 533.569577 155.696966 45.2284422 155.696966"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-2"
                transform="translate(289.399009, 207.017431) rotate(-210.000000) translate(-289.399009, -207.017431) "
                points="31.4721804 189.421843 547.325838 189.421843 561.680198 224.613019 17.1178203 224.613019"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-3"
                transform="translate(289.399009, 270.068288) rotate(-210.000000) translate(-289.399009, -270.068288) "
                points="5.75395187 252.4727 573.044067 252.4727 587.398427 287.663876 -8.60040825 287.663876"
              ></polygon>
              <polygon
                id="Rectangle-5-Copy-4"
                transform="translate(289.399009, 331.652846) rotate(-210.000000) translate(-289.399009, -331.652846) "
                points="-19.3661783 314.057258 598.164197 314.057258 612.518557 349.248434 -33.7205385 349.248434"
              ></polygon>
            </g>
          </g>
          <g
            id="wires"
            opacity="0.2"
            transform="translate(125.000000, -100.000000)"
            fillRule="nonzero"
            stroke="#000000"
            strokeWidth="4"
          >
            <path
              d="M541.544009,349.77092 C541.544009,366.98822 541.544009,383.351142 541.544009,398.859686 C541.544009,412.494679 534.638014,419.312175 520.826025,419.312175 C464.124498,419.312175 410.402753,419.312175 359.660789,419.312175 C347.503878,419.312175 341.425423,412.494679 341.425423,398.859686 C341.425423,384.401114 341.425423,371.744264 341.425423,360.889136 C341.425423,346.012059 334.884291,338.57352 321.802027,338.57352 C273.621199,338.57352 228.417054,338.57352 186.189592,338.57352 C166.378331,338.57352 156.4727,346.012059 156.4727,360.889136 C156.4727,569.744632 156.4727,683.833279 156.4727,703.155078 C156.4727,727.723527 166.378331,740.007751 186.189592,740.007751 L299.70067,740.007751"
              id="Path-8"
            ></path>
            <path
              d="M473.5,346.932587 C473.5,393.517276 473.5,436.144841 473.5,474.815282 C473.5,490.783437 466.343474,498.767514 452.030421,498.767514 C295.662631,499.434181 152.319157,499.767514 22,499.767514 C7.27177263,499.767514 0.101600144,508.880164 0.489482546,527.105463 L0.489482546,660.112436"
              id="Path-10"
            ></path>
            <path
              d="M185,710.428763 L185,645.582187 C185,633.543819 193.814821,627.524635 211.444463,627.524635 C273.382476,627.524635 237.56476,627.524635 295.944075,627.524635 C316.330913,627.524635 326.524332,635.910625 326.524332,652.682605 C326.524332,665.782357 326.524332,676.88433 326.524332,685.988522 C326.524332,708.655939 339.324393,710.428763 355.095461,710.428763 C377.772446,710.428763 401.131754,710.428763 425.173385,710.428763 C461.037002,710.428763 461.037002,706.657473 461.037002,676.133259 C461.037002,620.367965 461.037002,524.026522 461.037002,387.108931 C461.037002,370.369644 452.196663,362 434.515984,362 C410.425706,362 385.358411,362 359.314099,362"
              id="Path-3"
              transform="translate(323.018501, 536.214382) rotate(-180.000000) translate(-323.018501, -536.214382) "
            ></path>
            <path
              d="M485.5,356.216715 C485.5,396.156681 485.5,430.230105 485.5,458.436988 C485.5,479.249599 495.906306,489.655905 516.718917,489.655905 C661.974501,489.655905 796.894388,489.655905 921.478577,489.655905 C936.172953,489.655905 943.520141,497.003093 943.520141,511.697469 C943.520141,517.30769 943.520141,523.588629 943.520141,530.540284 C943.520141,541.09783 948.798914,546.376603 959.356461,546.376603 L980.330617,546.376603"
              id="Path-9"
            ></path>
            <path
              d="M496,358 C496,383.738809 496,408.937162 496,433.595059 C496,448.898484 506.073229,456.550196 526.219688,456.550196 C544.037242,456.550196 565.465614,456.550196 590.504802,456.550196 C604.834934,456.550196 612,462.511161 612,474.433092 L612,748"
              id="Path-5"
            ></path>
            <path
              d="M558.553031,360.536032 C558.553031,373.635784 558.553031,384.737757 558.553031,393.841949 C558.553031,416.509366 571.353092,418.28219 587.12416,418.28219 C609.801145,418.28219 616.160453,418.28219 640.202084,418.28219 C676.065701,418.28219 676.065701,414.5109 676.065701,383.986686 C676.065701,328.221392 676.065701,224.353627 676.065701,72.3833913 L853.705027,0.727485751"
              id="Path-3"
            ></path>
          </g>
          <g
            id="geyser"
            transform="translate(359.000000, 416.000000)"
            fillRule="nonzero"
            stroke="#000000"
            strokeWidth="4"
          >
            <rect
              id="Rectangle"
              fill="#E3E3E3"
              x="52"
              y="2"
              width="519"
              height="238"
              rx="10"
            ></rect>
            <path
              d="M5.76649761,232 C3.04557472,232 1.69335051,233.232304 2.07182221,234.581928 L10.9771017,266.338026 C11.5056883,268.222958 14.3592423,270 17.2761575,270 L599.723842,270 C602.640758,270 605.494312,268.222958 606.022898,266.338026 L614.928178,234.581928 C615.306649,233.232304 613.954425,232 611.233502,232 L5.76649761,232 Z"
              id="Rectangle-2"
              fill="#FFFFFF"
            ></path>
          </g>
          <g
            id="left-Inlet"
            transform="translate(110.000000, 280.000000)"
            fillRule="nonzero"
          >
            <rect
              id="Rectangle-4-Copy-3"
              stroke="#000000"
              strokeWidth="4"
              fill="#E3E3E3"
              x="263"
              y="11"
              width="13"
              height="280"
              rx="4"
            ></rect>
            <path
              d="M64.8890956,384 L64.8890956,98 L264.236305,98 C265.340875,98 266.236305,97.1045695 266.236305,96 L266.236305,87 C266.236305,85.8954305 265.340875,85 264.236305,85 L54,85 C52.9130709,85 52.025509,85.868528 52.0005499,86.9521661 L51.9955964,87.1672284 L51.9450213,87.3763181 C51.9080408,87.5292042 51.8890956,87.6875095 51.8890956,87.8498953 L51.8890956,384 C51.8890956,385.104569 52.7845261,386 53.8890956,386 L62.8890956,386 C63.9936651,386 64.8890956,385.104569 64.8890956,384 Z"
              id="Combined-Shape"
              stroke="#000000"
              strokeWidth="4"
              fill="#E3E3E3"
            ></path>
            <g
              id="Group-5"
              transform="translate(104.085916, 90.441165) rotate(-270.000000) translate(-104.085916, -90.441165) translate(48.085916, 76.441165)"
            >
              <rect
                id="Rectangle-4-Copy-9"
                stroke="#000000"
                strokeWidth="4"
                fill="#FFFFFF"
                x="43"
                y="5.38142073"
                width="27"
                height="16.6068052"
                rx="4"
              ></rect>
              <g id="Group-3" transform="translate(66.000000, 0.000000)">
                <rect
                  id="Rectangle-5-Copy"
                  fill="#000000"
                  transform="translate(31.000000, 14.500000) rotate(-270.000000) translate(-31.000000, -14.500000) "
                  x="23.5"
                  y="0.5"
                  width="15"
                  height="28"
                  rx="4"
                ></rect>
                <rect
                  id="Rectangle-4-Copy-9"
                  stroke="#000000"
                  strokeWidth="4"
                  fill="#FFFFFF"
                  transform="translate(17.223602, 14.000000) rotate(-180.000000) translate(-17.223602, -14.000000) "
                  x="2.44720371"
                  y="2"
                  width="29.5527963"
                  height="24"
                  rx="4"
                ></rect>
              </g>
              <g
                id="Group-3-Copy"
                transform="translate(23.500000, 14.000000) scale(-1, 1) translate(-23.500000, -14.000000) "
              >
                <rect
                  id="Rectangle-5-Copy"
                  fill="#000000"
                  transform="translate(32.223602, 13.500000) rotate(-270.000000) translate(-32.223602, -13.500000) "
                  x="24.7236019"
                  y="-0.5"
                  width="15"
                  height="28"
                  rx="4"
                ></rect>
                <rect
                  id="Rectangle-4-Copy-9"
                  stroke="#000000"
                  strokeWidth="4"
                  fill="#FFFFFF"
                  transform="translate(18.223602, 14.000000) rotate(-180.000000) translate(-18.223602, -14.000000) "
                  x="2.44720371"
                  y="2"
                  width="31.5527963"
                  height="24"
                  rx="4"
                ></rect>
              </g>
            </g>
            <rect
              id="Rectangle-4-Copy-4"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              transform="translate(268.500000, 277.500000) rotate(-90.000000) translate(-268.500000, -277.500000) "
              x="259"
              y="267"
              width="19"
              height="21"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4-Copy-5"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              transform="translate(269.500000, 89.500000) rotate(-90.000000) translate(-269.500000, -89.500000) "
              x="249"
              y="79"
              width="41"
              height="21"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4-Copy"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="240"
              y="287"
              width="54"
              height="10"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="288"
              y="282"
              width="13"
              height="21"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4-Copy-2"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="236"
              y="284"
              width="13"
              height="18"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4-Copy-6"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              transform="translate(269.500000, 9.000000) rotate(-270.000000) translate(-269.500000, -9.000000) "
              x="263"
              y="0"
              width="13"
              height="18"
              rx="4"
            ></rect>
            <g id="Group-4" transform="translate(0.000000, 246.000000)">
              <rect
                id="Rectangle-4-Copy-13"
                stroke="#000000"
                strokeWidth="4"
                fill="#FFFFFF"
                x="35"
                y="14"
                width="13"
                height="18"
                rx="4"
              ></rect>
              <rect
                id="Rectangle-4-Copy-11"
                stroke="#000000"
                strokeWidth="4"
                fill="#FFFFFF"
                transform="translate(56.500000, 22.500000) rotate(-90.000000) translate(-56.500000, -22.500000) "
                x="36"
                y="12"
                width="41"
                height="21"
                rx="4"
              ></rect>
              <rect
                id="Rectangle-4-Copy-12"
                stroke="#000000"
                strokeWidth="4"
                fill="#FFFFFF"
                transform="translate(20.000000, 22.500000) rotate(-90.000000) translate(-20.000000, -22.500000) "
                x="2.58823529"
                y="4.5"
                width="34.8235294"
                height="36"
                rx="4"
              ></rect>
              <path
                d="M20.7275953,17.78 C20.7575953,17.53 20.7775953,17.27 20.7775953,17 C20.7775953,16.73 20.7575953,16.47 20.7175953,16.22 L22.4075953,14.9 C22.5575953,14.78 22.5975953,14.56 22.5075953,14.39 L20.9075953,11.62 C20.8075953,11.44 20.5975953,11.38 20.4175953,11.44 L18.4275953,12.24 C18.0075953,11.92 17.5675953,11.66 17.0775953,11.46 L16.7775953,9.34 C16.7475953,9.14 16.5775953,9 16.3775953,9 L13.1775953,9 C12.9775953,9 12.8175953,9.14 12.7875953,9.34 L12.4875953,11.46 C11.9975953,11.66 11.5475953,11.93 11.1375953,12.24 L9.14759532,11.44 C8.96759532,11.37 8.75759532,11.44 8.65759532,11.62 L7.05759532,14.39 C6.95759532,14.57 6.99759532,14.78 7.15759532,14.9 L8.84759532,16.22 C8.80759532,16.47 8.77759532,16.74 8.77759532,17 C8.77759532,17.26 8.79759532,17.53 8.83759532,17.78 L7.14759532,19.1 C6.99759532,19.22 6.95759532,19.44 7.04759532,19.61 L8.64759532,22.38 C8.74759532,22.56 8.95759532,22.62 9.13759532,22.56 L11.1275953,21.76 C11.5475953,22.08 11.9875953,22.34 12.4775953,22.54 L12.7775953,24.66 C12.8175953,24.86 12.9775953,25 13.1775953,25 L16.3775953,25 C16.5775953,25 16.7475953,24.86 16.7675953,24.66 L17.0675953,22.54 C17.5575953,22.34 18.0075953,22.07 18.4175953,21.76 L20.4075953,22.56 C20.5875953,22.63 20.7975953,22.56 20.8975953,22.38 L22.4975953,19.61 C22.5975953,19.43 22.5575953,19.22 22.3975953,19.1 L20.7275953,17.78 Z M14.7775953,20 C13.1275953,20 11.7775953,18.65 11.7775953,17 C11.7775953,15.35 13.1275953,14 14.7775953,14 C16.4275953,14 17.7775953,15.35 17.7775953,17 C17.7775953,18.65 16.4275953,20 14.7775953,20 Z"
                id="Shape"
                fill="#000000"
              ></path>
              <path
                d="M32.7275953,29.78 C32.7575953,29.53 32.7775953,29.27 32.7775953,29 C32.7775953,28.73 32.7575953,28.47 32.7175953,28.22 L34.4075953,26.9 C34.5575953,26.78 34.5975953,26.56 34.5075953,26.39 L32.9075953,23.62 C32.8075953,23.44 32.5975953,23.38 32.4175953,23.44 L30.4275953,24.24 C30.0075953,23.92 29.5675953,23.66 29.0775953,23.46 L28.7775953,21.34 C28.7475953,21.14 28.5775953,21 28.3775953,21 L25.1775953,21 C24.9775953,21 24.8175953,21.14 24.7875953,21.34 L24.4875953,23.46 C23.9975953,23.66 23.5475953,23.93 23.1375953,24.24 L21.1475953,23.44 C20.9675953,23.37 20.7575953,23.44 20.6575953,23.62 L19.0575953,26.39 C18.9575953,26.57 18.9975953,26.78 19.1575953,26.9 L20.8475953,28.22 C20.8075953,28.47 20.7775953,28.74 20.7775953,29 C20.7775953,29.26 20.7975953,29.53 20.8375953,29.78 L19.1475953,31.1 C18.9975953,31.22 18.9575953,31.44 19.0475953,31.61 L20.6475953,34.38 C20.7475953,34.56 20.9575953,34.62 21.1375953,34.56 L23.1275953,33.76 C23.5475953,34.08 23.9875953,34.34 24.4775953,34.54 L24.7775953,36.66 C24.8175953,36.86 24.9775953,37 25.1775953,37 L28.3775953,37 C28.5775953,37 28.7475953,36.86 28.7675953,36.66 L29.0675953,34.54 C29.5575953,34.34 30.0075953,34.07 30.4175953,33.76 L32.4075953,34.56 C32.5875953,34.63 32.7975953,34.56 32.8975953,34.38 L34.4975953,31.61 C34.5975953,31.43 34.5575953,31.22 34.3975953,31.1 L32.7275953,29.78 Z M26.7775953,32 C25.1275953,32 23.7775953,30.65 23.7775953,29 C23.7775953,27.35 25.1275953,26 26.7775953,26 C28.4275953,26 29.7775953,27.35 29.7775953,29 C29.7775953,30.65 28.4275953,32 26.7775953,32 Z"
                id="Shape-Copy"
                fill="#000000"
              ></path>
            </g>
          </g>
          <g
            id="right-Inlet"
            transform="translate(829.000000, 261.000000)"
            fillRule="nonzero"
            stroke="#000000"
            strokeWidth="4"
          >
            <rect
              id="Rectangle-4-Copy-3"
              fill="#E3E3E3"
              x="271"
              y="10"
              width="13"
              height="297"
              rx="4"
            ></rect>
            <path
              d="M124.5627,251.326395 L124.5627,-7.67360487 L173.5627,-7.67360487 C174.66727,-7.67360487 175.5627,-8.56903537 175.5627,-9.67360487 L175.5627,-18.6736049 C175.5627,-19.7781744 174.66727,-20.6736049 173.5627,-20.6736049 L113.673605,-20.6736049 C112.586676,-20.6736049 111.699114,-19.8050769 111.674155,-18.7214388 L111.669201,-18.5063765 L111.618626,-18.2972868 C111.581646,-18.1444007 111.5627,-17.9860954 111.5627,-17.8237096 L111.5627,251.326395 C111.5627,252.430965 112.458131,253.326395 113.5627,253.326395 L122.5627,253.326395 C123.66727,253.326395 124.5627,252.430965 124.5627,251.326395 Z"
              id="Combined-Shape"
              fill="#E3E3E3"
              transform="translate(143.562700, 116.326395) scale(-1, 1) rotate(90.000000) translate(-143.562700, -116.326395) "
            ></path>
            <rect
              id="Rectangle-4-Copy-5"
              fill="#FFFFFF"
              transform="translate(277.500000, 88.500000) rotate(-90.000000) translate(-277.500000, -88.500000) "
              x="257"
              y="78"
              width="41"
              height="21"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4"
              fill="#FFFFFF"
              transform="translate(12.500000, 150.500000) rotate(-270.000000) translate(-12.500000, -150.500000) "
              x="6"
              y="140"
              width="13"
              height="21"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-4-Copy-6"
              fill="#FFFFFF"
              transform="translate(277.000000, 8.500000) rotate(-270.000000) translate(-277.000000, -8.500000) "
              x="270.5"
              y="-0.5"
              width="13"
              height="18"
              rx="4"
            ></rect>
          </g>
          <g
            id="flow-arrows"
            transform="translate(156.000000, 555.000000)"
            fillRule="nonzero"
          >
            <rect
              id="Rectangle-6"
              fill="#FFFFFF"
              x="0"
              y="103"
              width="25"
              height="17"
            ></rect>
            <rect
              id="Rectangle-6-Copy-2"
              fill="#FFFFFF"
              x="939"
              y="0"
              width="25"
              height="17"
            ></rect>
            <path
              id="Line-2"
              d="M12.5,105.5 L5.5,119.5 L19.5,119.5 L12.5,105.5 Z M13.5,139 L13.5,118.5 L13.5,117.5 L11.5,117.5 L11.5,118.5 L11.5,139 L11.5,140 L13.5,140 L13.5,139 Z"
              fill="#4A90E2"
            ></path>
            <path
              id="Line-2-Copy"
              d="M951,40 L958,26 L944,26 L951,40 Z M950,6 L950,27 L950,28 L952,28 L952,27 L952,6 L952,5 L950,5 L950,6 Z"
              fill="#4A90E2"
            ></path>
          </g>
          <g
            id="controller"
            transform="translate(567.000000, 34.000000)"
            fillRule="nonzero"
          >
            <rect
              id="Rectangle-7"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="229"
              y="2"
              width="11"
              height="11"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-7-Copy"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="229"
              y="59"
              width="11"
              height="11"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-7-Copy-2"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="111"
              y="222"
              width="11"
              height="11"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-7-Copy-2"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="94"
              y="222"
              width="11"
              height="11"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-7-Copy-5"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="11"
              y="222"
              width="50"
              height="11"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-3"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="2"
              y="59"
              width="129"
              height="166"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-3-Copy"
              stroke="#000000"
              strokeWidth="4"
              x="15"
              y="80"
              width="103"
              height="55"
              rx="4"
            ></rect>
            <circle
              id="Oval-2"
              stroke="#000000"
              strokeWidth="2"
              cx="28"
              cy="92"
              r="3"
            ></circle>
            <circle
              id="Oval-2-Copy"
              stroke="#000000"
              strokeWidth="2"
              cx="28"
              cy="102"
              r="3"
            ></circle>
            <circle
              id="Oval-2-Copy-2"
              stroke="#000000"
              strokeWidth="2"
              cx="28"
              cy="112"
              r="3"
            ></circle>
            <circle
              id="Oval-2-Copy-3"
              stroke="#000000"
              strokeWidth="2"
              cx="28"
              cy="122"
              r="3"
            ></circle>
            <path d="" id="Path-2" stroke="#979797"></path>
            <rect
              id="Rectangle-8"
              stroke="#000000"
              strokeWidth="4"
              fill="#FFFFFF"
              x="213"
              y="11"
              width="42"
              height="50"
              rx="4"
            ></rect>
            <rect
              id="Rectangle-9"
              fill="#000000"
              x="226"
              y="24"
              width="16"
              height="26"
              rx="4"
            ></rect>
            <path
              d="M226.268209,36.9599348 L241.960377,36.9599348"
              id="Path-4"
              stroke="#FFFFFF"
            ></path>
          </g>
          <g id="labels" transform="translate(74.000000, 13.000000)">
            <g id="Info" transform="translate(7.000000, 0.000000)">
              <g
                id="External_Temp_Info"
                transform="translate(861.000000, 436.000000)"
              >
                <g id="Bubble-Copy-2">
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-2)"
                      href="#path-1"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-1"
                    ></use>
                  </g>
                  <text
                    id="External_Temp_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.759705901"
                    fill="#000000"
                  >
                    <tspan x="8" y="41">
                      External temp
                    </tspan>
                  </text>
                  <g
                    id="Green_Badge"
                    transform="translate(171.000000, 0.000000)"
                  >
                    <g
                      id="Group-2-Copy-4"
                      filter="url(#filter-3)"
                      transform="translate(0.742489, 0.000000)"
                    >
                      <ellipse
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                        cx="36.1287554"
                        cy="34.5"
                        rx="36.1287554"
                        ry="34.5"
                      ></ellipse>
                      <ellipse
                        id="External_Temp_Colour"
                        fill="#6BAF1D"
                        fillRule="nonzero"
                        cx="35.7982833"
                        cy="34.5"
                        rx="28.7982833"
                        ry="27.5"
                      ></ellipse>
                      <text
                        id="External_Temp_Value"
                        fontFamily="ArialMT, Arial"
                        fontSize="20"
                        fontWeight="normal"
                        letterSpacing="-0.607764721"
                        fill="#FFFFFF"
                      >
                        <tspan x="15.7966721" y="39">
                          {externalTemp()}
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
              <g
                id="Ambient_Temp_Info"
                transform="translate(0.000000, 59.000000)"
              >
                <g id="Bubble-Copy-2">
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-5)"
                      href="#path-4"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-4"
                    ></use>
                  </g>
                  <text
                    id="Ambient_Temp_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.759705901"
                    fill="#000000"
                  >
                    <tspan x="8" y="40">
                      Ambient temp
                    </tspan>
                  </text>
                  <g
                    id="Green_Badge"
                    transform="translate(171.000000, 0.000000)"
                  >
                    <g
                      id="Group-2-Copy-4"
                      filter="url(#filter-6)"
                      transform="translate(0.742489, 0.000000)"
                    >
                      <ellipse
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                        cx="36.1287554"
                        cy="34.5"
                        rx="36.1287554"
                        ry="34.5"
                      ></ellipse>
                      <ellipse
                        id="Ambient_Temp_Colour"
                        fill="#6BAF1D"
                        fillRule="nonzero"
                        cx="35.7982833"
                        cy="33.5"
                        rx="28.7982833"
                        ry="27.5"
                      ></ellipse>
                      <text
                        id="Ambient_Temp_Value"
                        fontFamily="ArialMT, Arial"
                        fontSize="20"
                        fontWeight="normal"
                        letterSpacing="-0.607764721"
                        fill="#FFFFFF"
                      >
                        <tspan x="15.7966721" y="41">
                          {ambientTempVal()}
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
              <g
                id="Internal_Temp_Info"
                transform="translate(462.000000, 427.000000)"
              >
                <g
                  id="Bubble_Text-Copy-2"
                  transform="translate(45.000000, 12.000000)"
                >
                  <g id="Bubble-Copy-2">
                    <g id="Rectangle">
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-8)"
                        href="#path-7"
                      ></use>
                      <use
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        href="#path-7"
                      ></use>
                    </g>
                    <text
                      id="Internal_Temp_Label"
                      fontFamily={styles.note.fontFamily}
                      fontSize="25"
                      fontWeight="normal"
                      letterSpacing="-0.7597059"
                      fill="#000000"
                    >
                      <tspan x="33" y="31">
                        Internal temp
                      </tspan>
                    </text>
                  </g>
                </g>
                <g id="Group-2-Copy-4" filter="url(#filter-9)">
                  <ellipse
                    id="Oval"
                    stroke="#000000"
                    strokeWidth="4"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    cx="36.1287554"
                    cy="34.5"
                    rx="36.1287554"
                    ry="34.5"
                  ></ellipse>
                  <ellipse
                    id="Internal_Temp_Colour"
                    fill="#6BAF1D"
                    fillRule="nonzero"
                    cx="35.7982833"
                    cy="34.5"
                    rx="28.7982833"
                    ry="27.5"
                  ></ellipse>
                  <text
                    id="Internal_Temp_Value"
                    fontFamily="ArialMT, Arial"
                    fontSize="20"
                    fontWeight="normal"
                    letterSpacing="-0.6077647"
                    fill="#FFFFFF"
                  >
                    <tspan x="18.4096105" y="41">
                      {internalTemp()}
                    </tspan>
                  </text>
                </g>
              </g>
              <g id="RSSI_Info" transform="translate(438.000000, 0.000000)">
                <g
                  id="Bubble_Text-Copy-2"
                  transform="translate(0.000000, 12.000000)"
                >
                  <g id="Bubble-Copy-2">
                    <g id="Rectangle">
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-11)"
                        href="#path-10"
                      ></use>
                      <use
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        href="#path-10"
                      ></use>
                    </g>
                    <text
                      id="RSSI_Label"
                      fontFamily={styles.note.fontFamily}
                      fontSize="25"
                      fontWeight="normal"
                      letterSpacing="-0.759705901"
                      fill="#000000"
                    >
                      <tspan x="15.0257511" y="31">
                        RSSI
                      </tspan>
                    </text>
                  </g>
                </g>
                <g
                  id="Group-2-Copy-5"
                  filter="url(#filter-12)"
                  transform="translate(79.000000, 0.000000)"
                >
                  <ellipse
                    id="Oval"
                    stroke="#000000"
                    strokeWidth="4"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    cx="36.1287554"
                    cy="34.5"
                    rx="36.1287554"
                    ry="34.5"
                  ></ellipse>
                  <ellipse
                    id="RSSI_Colour"
                    fill="#6BAF1D"
                    fillRule="nonzero"
                    cx="35.7982833"
                    cy="34.5"
                    rx="28.7982833"
                    ry="27.5"
                  ></ellipse>
                  <text
                    id="RSSI_Value"
                    fontFamily="ArialMT, Arial"
                    fontSize="20"
                    fontWeight="normal"
                    letterSpacing="-0.6077647"
                    fill="#FFFFFF"
                  >
                    <tspan x="26.4096105" y="41">
                      {rssiVal()}
                    </tspan>
                  </text>
                </g>
              </g>
              <g id="Mains_Info" transform="translate(750.000000, 21.000000)">
                <g
                  id="Bubble_Text-Copy"
                  transform="translate(44.000000, 13.000000)"
                >
                  <g id="Bubble-Copy-2">
                    <g id="Rectangle">
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-14)"
                        href="#path-13"
                      ></use>
                      <use
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        href="#path-13"
                      ></use>
                    </g>
                    <text
                      id="Mains_Label"
                      fontFamily={styles.note.fontFamily}
                      fontSize="25"
                      fontWeight="normal"
                      letterSpacing="-0.759705901"
                      fill="#000000"
                    >
                      <tspan x="33" y="31">
                        Mains
                      </tspan>
                    </text>
                  </g>
                </g>
                <g id="Group-2-Copy-4" filter="url(#filter-15)">
                  <ellipse
                    id="Oval"
                    stroke="#000000"
                    strokeWidth="4"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    cx="36.1287554"
                    cy="34.5"
                    rx="36.1287554"
                    ry="34.5"
                  ></ellipse>
                  <ellipse
                    id="Mains_Colour"
                    fill="#6BAF1D"
                    fillRule="nonzero"
                    cx="35.7982833"
                    cy="34.5"
                    rx="28.7982833"
                    ry="27.5"
                  ></ellipse>
                  <text
                    id="Mains_Value"
                    fontFamily="ArialMT, Arial"
                    fontSize="20"
                    fontWeight="normal"
                    letterSpacing="-0.6077647"
                    fill="#FFFFFF"
                  >
                    <tspan x="14.7859299" y="41">
                      {voltageVal()}
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
            <g id="Faults" transform="translate(0.000000, 167.000000)">
              <g
                id="Drip_Tray_Fault"
                transform="translate(667.000000, 428.000000)"
              >
                <g id="Group-7" transform="translate(27.000000, 12.000000)">
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-17)"
                      href="#path-16"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-16"
                    ></use>
                  </g>
                  <text
                    id="Drip_Tray_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.7597059"
                    fill="#000000"
                  >
                    <tspan x="47" y="32">
                      Drip tray
                    </tspan>
                  </text>
                </g>
                <g id="Group-2" fillRule="nonzero">
                  <g id="Fault_Badge">
                    <g id="Group-2" filter="url(#filter-18)">
                      <circle
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        cx="34.5"
                        cy="34.5"
                        r="34.5"
                      ></circle>
                      <circle
                        id="Drip_Tray_Colour"
                        fill="#D0021B"
                        cx="34.5"
                        cy="34.5"
                        r="27.5"
                      ></circle>
                    </g>
                  </g>
                  <g
                    id="Group"
                    transform="translate(22.000000, 14.000000)"
                    fill="#FFFFFF"
                    stroke="#000000"
                  >
                    <path
                      d="M12.9880913,0 C10.0993386,4.76469669 6.51507317,11.59803 2.2352949,20.5 C-4.65076994,34.823065 5.92643941,41 12.9880913,41 C20.0497431,41 30.7109289,33.6861438 23.717985,20.5 C20.0521816,13.5876307 16.4755504,6.75429741 12.9880913,0 Z"
                      id="Path-6"
                      strokeWidth="4"
                    ></path>
                    <path
                      d="M5.9228923,25.1406183 C3.55936303,28.5315745 5.9228923,34.8578543 10.8776214,35.8235834 C14.1807742,36.4674028 14.8631321,35.3490433 12.9246952,32.4685049 C11.6320699,31.4381281 9.84685075,29.9346604 7.56903771,27.9581018 C6.388934,26.9340739 6.45613917,24.3755688 5.9228923,25.1406183 Z"
                      id="Path-7"
                    ></path>
                  </g>
                </g>
              </g>
              <g
                id="Element_Open_Fault"
                transform="translate(309.000000, 415.000000)"
              >
                <g
                  id="Group-7-Copy"
                  transform="translate(27.000000, 11.000000)"
                >
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-20)"
                      href="#path-19"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-19"
                    ></use>
                  </g>
                  <text
                    id="Element_Open_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.7597059"
                    fill="#000000"
                  >
                    <tspan x="47" y="32">
                      Element
                    </tspan>
                  </text>
                </g>
                <g id="Group-2-Copy" fillRule="nonzero">
                  <g id="Fault_Badge">
                    <g id="Group-2" filter="url(#filter-21)">
                      <circle
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        cx="34.5"
                        cy="34.5"
                        r="34.5"
                      ></circle>
                      <circle
                        id="Element_Open_Colour"
                        fill="#D0021B"
                        cx="34.5"
                        cy="34.5"
                        r="27.5"
                      ></circle>
                    </g>
                  </g>
                  <g
                    id="bolt"
                    transform="translate(17.000000, 17.000000)"
                    fill="#FFFFFF"
                  >
                    <polygon
                      id="Shape"
                      points="35 0 8.75 17.5 15.3125 21.875 0 35 26.25 21.875 19.6875 17.5"
                    ></polygon>
                  </g>
                </g>
              </g>
              <g
                id="Water_Valve_Fault"
                transform="translate(0.000000, 393.000000)"
              >
                <g
                  id="Group-7-Copy-2"
                  transform="translate(27.000000, 11.000000)"
                >
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-23)"
                      href="#path-22"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-22"
                    ></use>
                  </g>
                  <text
                    id="Water_Valve_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.7597059"
                    fill="#000000"
                  >
                    <tspan x="47" y="32">
                      Water valve
                    </tspan>
                  </text>
                </g>
                <g id="Group-2-Copy-3" fillRule="nonzero">
                  <g id="Fault_Badge">
                    <g id="Group-2" filter="url(#filter-24)">
                      <circle
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        cx="34.5"
                        cy="34.5"
                        r="34.5"
                      ></circle>
                      <circle
                        id="Water_Valve_Colour"
                        fill="#D0021B"
                        cx="34.5"
                        cy="34.5"
                        r="27.5"
                      ></circle>
                    </g>
                  </g>
                  <g id="warning1" transform="translate(15.000000, 11.000000)">
                    <path
                      d="M19.5009525,5.41233725e-16 C18.6600052,5.41233725e-16 17.8202766,0.56671875 17.1816442,1.70015625 L0.53454405,34.8769688 C-0.740283299,37.1438438 0.344416835,39 2.94525963,39 L36.0542078,39 C38.6550506,39 39.7409695,37.1450625 38.4649234,34.8769688 L21.8178232,1.70015625 C21.1804095,0.56671875 20.3394622,0 19.4985149,0 L19.5009525,5.41233725e-16 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      points="19.5009525 3.53315625 35.8433616 36.1054688 3.1573246 36.1054688 19.4997337 3.53315625"
                    ></polygon>
                    <path
                      d="M22,31.5 C22,32.88125 20.88125,34 19.5,34 C18.11875,34 17,32.88125 17,31.5 C17,30.11875 18.11875,29 19.5,29 C20.88125,29 22,30.11875 22,31.5 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                    <path
                      d="M19.5,27 C18.11875,27 17,25.926 17,24.6 L17,17.4 C17,16.074 18.11875,15 19.5,15 C20.88125,15 22,16.074 22,17.4 L22,24.6 C22,25.926 20.88125,27 19.5,27 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                  </g>
                </g>
              </g>
              <g
                id="Master_Disable_Fault"
                transform="translate(528.000000, 0.000000)"
              >
                <g id="Bubble" transform="translate(37.000000, 11.000000)">
                  <g id="Rectangle">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-26)"
                      href="#path-25"
                    ></use>
                    <use
                      stroke="#000000"
                      strokeWidth="4"
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      href="#path-25"
                    ></use>
                  </g>
                  <text
                    id="Master_Disable_Label"
                    fontFamily={styles.note.fontFamily}
                    fontSize="25"
                    fontWeight="normal"
                    letterSpacing="-0.759705901"
                    fill="#000000"
                  >
                    <tspan x="39" y="31">
                      Master disable
                    </tspan>
                  </text>
                </g>
                <g id="Group-2-Copy-3" fillRule="nonzero">
                  <g id="Fault_Badge">
                    <g id="Group-2" filter="url(#filter-27)">
                      <circle
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="4"
                        fill="#FFFFFF"
                        cx="34.5"
                        cy="34.5"
                        r="34.5"
                      ></circle>
                      <circle
                        id="Master_Disable_Colour"
                        fill="#D0021B"
                        cx="34.5"
                        cy="34.5"
                        r="27.5"
                      ></circle>
                    </g>
                  </g>
                  <g id="warning1" transform="translate(15.000000, 11.000000)">
                    <path
                      d="M19.5009525,5.41233725e-16 C18.6600052,5.41233725e-16 17.8202766,0.56671875 17.1816442,1.70015625 L0.53454405,34.8769688 C-0.740283299,37.1438438 0.344416835,39 2.94525963,39 L36.0542078,39 C38.6550506,39 39.7409695,37.1450625 38.4649234,34.8769688 L21.8178232,1.70015625 C21.1804095,0.56671875 20.3394622,0 19.4985149,0 L19.5009525,5.41233725e-16 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      points="19.5009525 3.53315625 35.8433616 36.1054688 3.1573246 36.1054688 19.4997337 3.53315625"
                    ></polygon>
                    <path
                      d="M22,31.5 C22,32.88125 20.88125,34 19.5,34 C18.11875,34 17,32.88125 17,31.5 C17,30.11875 18.11875,29 19.5,29 C20.88125,29 22,30.11875 22,31.5 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                    <path
                      d="M19.5,27 C18.11875,27 17,25.926 17,24.6 L17,17.4 C17,16.074 18.11875,15 19.5,15 C20.88125,15 22,16.074 22,17.4 L22,24.6 C22,25.926 20.88125,27 19.5,27 Z"
                      id="Shape"
                      fill="#000000"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { events: state.eventsReducer.events };
};

export default connect(mapStateToProps)(GeyserSvg);
