import  environment from '../../config.js';
const axios = require('axios').default;

export async function getUser(userId) {
    const api = `${environment.api}/users/${userId}`;
    let response = await axios.get(api)

    if (response.status !== 200) {
        console.log('Error:', response);
        return await response
    } else {
        return await response.data
    }
}