import environment from "../../config.js";
import { Observable, BehaviorSubject } from "rxjs";

let SensorTracker: any;

export class SniotService {
  client: any;
  clientSubject: BehaviorSubject<any>;
  fragment: string;
  constructor(SNIOT) {
    SensorTracker = SNIOT;

    const token = sessionStorage.getItem("token");
    if (token) {
      this.fragment =
        "access_token=" + token + "&token_type=Bearer&expires_in=3600";
    }
  }

  getClient() {
    const self = this;
    if (typeof SensorTracker !== "object") {
      setTimeout(() => {
        this.getClient();
      }, 1000);
    }
    if (!this.client && typeof SensorTracker === "object") {
      const authorizer = new SensorTracker.IFrameAuthorizer(
        {
          oauthAuthorizeUrl: environment.socket.oauthAuthorizeUrl,
          clientId: environment.clientId,
        },
        this.fragment
      );
      authorizer.start();
      this.client = SensorTracker.createClient({
        url: this.http2ws(environment.socket.websocketServerUrl) + "ws",
        authorizer: authorizer,
      });

      this.client.start().then(() => {
        self.clientSubject.next(self.client);
        return self.client;
      });
    }
    if (this.clientSubject) {
      return this.clientSubject;
    } else {
      this.clientSubject = new BehaviorSubject(null);
    }
    return this.clientSubject;
  }

  http2ws(url: string): string {
    return url.replace(/^http(s?):/, "ws$1:");
  }

  bindCollection(collection, filter) {
    return Observable.create((observer) => {
      collection.open();
      const view = collection.getView(filter);
      function digestor() {
        observer.next(view);
      }

      view.on("updated", digestor);
      view.on("error", (e) => {
        console.log("on error => ", e);
      });
      return function () {
        // dispose:
        try {
          view.removeListener("updated", digestor);
          view.close();
        } catch (e) {
          // could not close for some reason:
          console.log(e);
        }
      };
    });
  }

  updateDevice(snn: string, data: any) {
    return new Promise((resolve, reject) => {
      this.client.deviceShadow
        .updateDesiredDeviceShadow({
          snn: snn,
          desiredState: data,
        })
        .then((result) => {
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
