/* eslint-disable no-unused-vars */
import { default as environment } from "../../config.js";
require("chartist-plugin-tooltips");
require("chartist-plugin-legend");
var Chartist = require("chartist");

var moment = require("moment");
export interface ExportField {
  title: string;
  value: (i: any) => string;
}

export class UtilsService {
  apiBase: string;
  dayArray: Array<{ long: string; short: string }> = [
    {
      long: "Sunday",
      short: "Sun",
    },
    {
      long: "Monday",
      short: "Mon",
    },
    {
      long: "Tuesday",
      short: "Tue",
    },
    {
      long: "Wednesday",
      short: "Wed",
    },
    {
      long: "Thursday",
      short: "Thu",
    },
    {
      long: "Friday",
      short: "Fri",
    },
    {
      long: "Saterday",
      short: "Sat",
    },
  ];

  constructor() {
    this.apiBase = environment.api;
  }

  getValue(checkObject: any, properties: string) {
    if (!checkObject || !properties) return null;
    const props = properties.split(".");
    let obj = checkObject;
    props.some((prop) => {
      obj = obj[prop];
      if (!obj) return true;
      return false;
    });
    return obj;
  }

  currentDisplayedError: string = "";

  getUrlVars(str: string): any {
    if (str) {
      const vars: any = {};
      const hash = str.split("&");
      let params: string[] = [];
      for (let i = 0; i < hash.length; i++) {
        params = hash[i].split("=");
        vars[params[0]] = params[1];
      }
      return vars;
    } else {
      return {};
    }
  }

  parseQueryString(queryString: string): any {
    const params: any = {};
    let queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split("&");
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=");
      params[temp[0]] = temp[1];
    }
    return params;
  }

  handleHttpError(error: any, display = false) {
    const errorMsg = this.getValue(error, "error.errors.message");
    const errorMsg2 = this.getValue(error, "error.message");
    const msg = errorMsg
      ? errorMsg
      : errorMsg2
      ? errorMsg2
      : "An unknown error occurred";
    if (display) {
      console.log("Error message:", msg);
      // TODO: handle this error?
      // this.alertDialog.show('Error', msg);
      // this.displayError(msg);
    }
  }

  handleGraphError(
    graphError: any,
    display = false,
    observer?: any,
    count?: any
  ) {
    const networkError = graphError.networkError;
    let message = "";
    const errors = this.getValue(networkError, "error.errors");
    if (errors) {
      message = errors.map((err: { message: any }) => err.message).join(". ");
    } else if (graphError) {
      const graphErrorStr = graphError + "";
      let errorMsg = graphErrorStr.substring(
        graphErrorStr.indexOf("message:") + 8
      );
      errorMsg = errorMsg.substring(0, errorMsg.indexOf(","));
      errorMsg = errorMsg.replace(/"/g, "").trim();
      console.log("graphErrorObj", message);
      message = errorMsg;
    } else {
      message = "An unknown error occurred";
    }

    if (display && this.currentDisplayedError !== message) {
      this.currentDisplayedError = message;
      //   this.alertDialog.show('Error', message).subscribe(ret => {
      //     if (ret) this.currentDisplayedError = '';
      //   });
      // this.displayError(msg);
    }
  }

  getMax(array) {
    let max = this.clone(array)[0];
    array.forEach((item) => {
      if (item > max) max = item;
    });
    return max;
  }
  getMin(array) {
    let min = this.clone(array)[0];
    array.forEach((item) => {
      if (item < min) min = item;
    });
    return min;
  }

  setHighLow(localChart, margin = 10) {
    if (localChart.type !== "Pie") {
      const seriesMin = this.getMin([].concat(...localChart.data.series));
      localChart.options.low = seriesMin - (seriesMin / 100) * margin;
    }
    if (localChart.type !== "Pie") {
      const seriesMax = this.getMax([].concat(...localChart.data.series));
      localChart.options.high = seriesMax + (seriesMax / 100) * margin;
    }
    return localChart;
  }

  rssiToColor2(value) {
    let val;
    val = ((value / 30) * 100 - 100) * -1;
    if (val > 100) {
      val = 100;
    } else if (val < 0) {
      val = 0;
    }

    val = Math.floor(val);

    /* Customise */
    const GColor = (r = undefined, g = undefined, b = undefined) => {
      r = typeof r === "undefined" ? 0 : Math.floor(r);
      g = typeof g === "undefined" ? 0 : Math.floor(g);
      b = typeof b === "undefined" ? 0 : Math.floor(b);
      return { r: r, g: g, b: b };
    };

    const createColorRange = function (c1, c2) {
      const colorList = [];
      let tmpColor;
      for (let i = 0; i < 100; i++) {
        tmpColor = GColor();
        tmpColor.r = Math.floor(c1.r + (i * (c2.r - c1.r)) / 100);
        tmpColor.g = Math.floor(c1.g + (i * (c2.g - c1.g)) / 100);
        tmpColor.b = Math.floor(c1.b + (i * (c2.b - c1.b)) / 100);
        colorList.push(tmpColor);
      }
      return colorList;
    };

    const red = GColor(107, 175, 29);
    const blue = GColor(208, 2, 27);
    const range = createColorRange(red, blue);
    const currentColor = range[val];

    return (
      "rgb(" +
      currentColor.r +
      "," +
      currentColor.g +
      "," +
      currentColor.b +
      ")"
    );
  }

  getReported(attr, reported) {
    if (reported && attr in reported) {
      if (attr === "schedule") {
        return this.formatSchedule(reported[attr], reported);
      } else {
        return attr === "energy"
          ? Number(reported[attr]) / 1000
          : reported[attr];
      }
    }
    return "?";
  }

  formatSchedule(attr, reported) {
    return "";
  }
  reportedRounded(attr, reported) {
    if (reported && attr in reported) {
      return attr === "schedule"
        ? this.formatSchedule(reported[attr], reported)
        : Math.round(reported[attr]);
    }
    return 0;
  }

  getBoolean = (attr, reported) => {
    return this.getReported(attr, reported) === "?"
      ? false
      : this.getReported(attr, reported);
  };

  getChartData = (props, propString) => {
    const eventData = props?.events?.data;
    if (!eventData) return;
    let keys = eventData.map((event) => event.key);
    let series = eventData.map((event, index) => {
      return {
        meta: moment(new Date(keys[index])).format("h:mm A DD MMM yyyy"),
        value: event[propString].value,
      };
    });

    const data = {
      labels: keys,
      series: [series],
    };
    return data;
  };

  limitChartData = (data, seriesAmmount) => {
    const limitSeries = (labels, amount) => {
      if (!labels) return labels;
      const interval = Math.round(labels.length / amount);
      if (interval === 0) return labels;

      let subset = [];
      for (let i = 0; i < labels.length; i += interval) {
        subset.push(labels[i]);
      }
      return subset;
    };

    data.labels = limitSeries(data.labels, seriesAmmount);
    data.series[0] = limitSeries(data.series[0], seriesAmmount);
    return data;
  };

  chartDataSecondsToMinutes = (chartData) => {
    const data = this.clone(chartData);
    data.series = data.series.map((series) => {
      return series.map((bar) => {
        return bar / 60;
      });
    });
    return data;
  };

  initChartData = (chart, props, interval = 50) => {
    const data = this.getChartData(props, chart.dataProp);
    if (data) {
      if (chart.type === "Bar") {
        const barData = this.getBarChartData(data);
        chart.data =
          chart?.dataType === "time"
            ? this.chartDataSecondsToMinutes(barData)
            : barData;
      } else if (chart.type === "Pie") {
        const barData = this.getBarChartData(data);
        barData.series = barData.series[0];
        if (chart.increment === "week") {
          chart.data = this.aggriateByDay(barData);
        } else {
          chart.data = barData;
        }
      } else {
        chart.data = this.limitChartData(data, 400);
        chart = this.setlableFrequesncy(chart, interval);
        this.setHighLow(chart, 2);
      }
    }
    if (!chart.options) return;
    chart.options.plugins = this.createChartPlugins(chart);
  };

  aggriateByDay = (data) => {
    let dayIndex = 0;
    let dayArray = [0, 0, 0, 0, 0, 0, 0];
    data.series.forEach((item, i) => {
      dayArray[dayIndex] += item.value ? item.value : item;
      dayIndex++;
      if (dayIndex > 6) dayIndex = 0;
    });

    let series = data.series.slice(0, 7);
    series = series.map((item, i) => {
      if (item.value) {
        item.value = dayArray[i];
      } else {
        item = dayArray[i];
      }
      return item;
    });

    let labels = data.labels.slice(0, 7);
    labels = labels.map((label) => {
      return label.substring(0, 3);
    });

    return { series, labels };
  };

  setlableFrequesncy = (chart, interval) => {
    if (chart.options.axisX) {
      chart.options.axisX.labelInterpolationFnc = (value, index) => {
        return this.dateLable(value, index, interval);
      };
    }
    return chart;
  };

  dateLable = (value, index, interval = 100) => {
    return index % interval === 0 && typeof value === "number"
      ? moment(new Date(value)).format("hh:mm DD/MM")
      : "";
  };

  getBarChartData = (data) => {
    const weeklyData = this.getBarChartWeeklyData(data?.series[0]);
    if (weeklyData) return weeklyData;
    return {
      labels: [""],
      series: [[0]],
    };
  };

  getBarChartWeeklyData = (data) => {
    if (!data) return;
    const dates = this.clone(data)
      .map((item) => {
        const meta = item.meta;
        const slice = item.meta.includes("AM")
          ? item.meta.indexOf("AM")
          : item.meta.indexOf("PM");
        return meta.slice(slice + 3);
      })
      .filter((v, i, a) => a.findIndex((val) => val === v) === i);

    let diffs = [];
    dates.map((date) => {
      const datetFilterdData = this.clone(data)
        .filter((item) => {
          return item.meta.includes(date);
        })
        .map((item) => {
          return item.value;
        });

      const min = this.getMin(datetFilterdData);
      const max = this.getMax(datetFilterdData);
      const diff = max - min;
      diffs.push(diff);
      return {
        date,
        diff,
      };
    });

    const weekDays = dates.map((day) => {
      return moment(new Date(day)).format("ddd DD/MM");
    });

    return {
      labels: weekDays,
      series: [diffs],
    };
  };

  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }

  getSeriesTotal(series) {
    if (!series || series.length === 0) return 0;
    var sum = (a, b) => {
      return a + b;
    };

    return series
      .map((item) => {
        if (item.value) return item.value;
        return item;
      })
      .reduce(sum);
  }

  getPieItemPercentage(value, total) {
    return Math.round((value / total) * 100) + "%";
  }

  createChartPlugins(chart) {
    const total = this.getSeriesTotal(chart.data.series);
    const chartType = chart.type;
    const dataTypeSting = chart.dataTypeSting;
    const transformToolTip = (val) => {
      return Number(val).toFixed(2) + " " + dataTypeSting;
    };
    const transformPieToolTip = (val) => {
      const pers = this.getPieItemPercentage(val, total);
      return `${Number(val).toFixed(2)}  ${dataTypeSting} (${pers})`;
    };

    let plugins = [];
    if (chartType === "Pie") {
      plugins.push(
        Chartist.plugins.tooltip({
          metaIsHTML: true,
          transformTooltipTextFnc: transformPieToolTip,
        })
      );
      plugins.push(Chartist.plugins.legend());
    } else {
      plugins.push(
        Chartist.plugins.tooltip({
          metaIsHTML: true,
          transformTooltipTextFnc: transformToolTip,
        })
      );
    }

    return plugins;
  }

  getSchedule(schedule) {
    let s = schedule ? schedule : "";
    if (!/^\d{16},\d{16},\d{16},\d{16},\d{16},\d{16},\d{16}$/.exec(s)) {
      s =
        "0000000000000000,0000000000000000,0000000000000000,0000000000000000," +
        "0000000000000000,0000000000000000,0000000000000000";
    }
    return s.split(",").map((day, idx) => {
      const darray = day.match(/.{1,4}/g);
      const dayArray = [
        this.dayArray[idx].short,
        this.toTimeFormat(darray[0]),
        this.toTimeFormat(darray[1]),
        this.toTimeFormat(darray[2]),
        this.toTimeFormat(darray[3]),
      ];

      return dayArray;
    });
  }

  toTimeFormat(str: string): string {
    return str.match(/.{1,2}/g).join(":");
  }

  stringToDate(str: string): Date {
    const date = new Date();
    date.setHours(Number(str.substr(0, 2)));
    date.setMinutes(Number(str.substr(2, 4)));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
}
