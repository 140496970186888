import environment from "../../config.js";
const axios = require("axios").default;

// eslint-disable-next-line no-unused-vars
export async function updateDesiredDeviceShadowA(deviceSnn, desiredState) {
  return await { state: true };
}
export async function updateDesiredDeviceShadow(deviceSnn, desiredState) {
  const api = `${environment.base}/device-shadow/shadows/${deviceSnn}`;
  let response = await axios.post(api, desiredState);

  if (response.status !== 200) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response.data;
  }
}
export async function getDeviceShadow(deviceSnn) {
  const api = `${environment.base}/device-shadow/shadows/${deviceSnn}`;
  let response = await axios.get(api);

  if (response.status !== 200) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response.data;
  }
}

export async function getContainersByUser() {
  const api = `${environment.base}/platform-service/containers/user`;
  let response = await axios.get(api);

  if (response.status !== 200) {
    console.log("Error:", response);
    return await null;
  } else {
    return await response?.data;
  }
}
export async function postPlatformServiceFunction(
  selectedDevice,
  value,
  functionSnn
) {
  const jwtToken = sessionStorage.getItem("token");

  if (!jwtToken) return;
  const xhr = new XMLHttpRequest();
  console.log("value", value);
  xhr.open(
    "POST",
    `${environment.base}/platform-service/object/${selectedDevice}/function`,
    true
  );
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Authorization", `Bearer ${jwtToken}`);
  xhr.send(
    JSON.stringify({
      functionSnn: functionSnn,
      value: `${value}`,
    })
  );
}
export async function getDeviceMeta(deviceSnn, meta) {
  const api = `${environment.api}/device-shadow/instances/${deviceSnn}/meta/${meta}`;

  let response = await axios.get(api);

  if (response.status !== 200) {
    console.log("Error:", response);
    return await null;
  } else {
    return response?.data;
  }
}

export async function updateSecdevDeviceInfo(deviceSnn, desiredState) {
  const meta = "secdev:deviceInfo";
  getDeviceMeta(deviceSnn, meta).then((responseMeta) => {
    if (responseMeta) {
      let updateMeta = { ...responseMeta };
      const keys = Object.keys(desiredState);
      keys.forEach((key) => {
        if (desiredState[key] === null) {
          delete updateMeta[key];
        } else if (updateMeta[key]) {
          updateMeta[key] = desiredState[key];
        } else {
          updateMeta = { ...updateMeta, [key]: desiredState[key] };
        }
      });

      updateDeviceMeta(deviceSnn, updateMeta, meta).then((res) => {
        return res;
      });
    } else {
      return null;
    }
  });
}
async function updateDeviceMeta(deviceSnn, desiredState, meta) {
  const api = `${environment.api}/device-shadow/instances/${deviceSnn}/meta/${meta}`;
  let response = await axios.post(api, desiredState);

  if (response.status !== 200) {
    console.log("Error:", response);
    return await response;
  } else {
    return await response.data;
  }
}

export async function updateZoneDeviceInfo(deviceSnn, data) {
  const zoneId = data.id;
  const meta = "secdev:deviceInfo";
  let deviceInfo = await getDeviceMeta(deviceSnn, meta);

  if (!deviceInfo) return;
  if (data.zoneHidden === true) {
    if (deviceInfo.zonesHidden) deviceInfo.zonesHidden[zoneId] = false;
    if (!deviceInfo.zonesHidden) deviceInfo.zonesHidden = { [zoneId]: false };
  }
  if (
    data.zoneHidden === false &&
    deviceInfo.zonesHidden &&
    deviceInfo.zonesHidden[zoneId] === false
  ) {
    delete deviceInfo.zonesHidden[zoneId];
  }

  if (data.zoneRoomType !== undefined) {
    if (deviceInfo.zoneRoomTypes)
      deviceInfo.zoneRoomTypes[zoneId] = data.zoneRoomType;
    if (!deviceInfo.zoneRoomTypes)
      deviceInfo.zoneRoomTypes = { [zoneId]: data.zoneRoomType };
  }
  if (data.zoneType !== undefined) {
    if (deviceInfo.zoneTypes) deviceInfo.zoneTypes[zoneId] = data.zoneType;
    if (!deviceInfo.zoneTypes)
      deviceInfo.zoneTypes = { [zoneId]: data.zoneType };
  }
  if (data.zoneName && data.zoneName !== "Zone " + data.id) {
    if (deviceInfo.zoneNames) deviceInfo.zoneNames[zoneId] = data.zoneName;
    if (!deviceInfo.zoneNames)
      deviceInfo.zoneNames = { [zoneId]: data.zoneName };
  }
  const updateResponse = await updateDeviceMeta(deviceSnn, deviceInfo, meta);
  return updateResponse;
}
