/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import useToken from "../../shared/services/useToken";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import {
  setEventsQuery,
  fetchEventsData,
} from "../../shared/redux/actions/eventsActions";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);
function HeaderLinks(props) {
  const [alert, setAlert] = React.useState(null);
  const titleAndTextAlert = (message) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Alert!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        {message}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const getTimeFilter = () => {
    const fullWeek = 1000 * 60 * 60 * 24 * 6;
    let endDate = new Date();
    let startDate = new Date(new Date().getTime() - fullWeek);
    startDate.setHours(0);
    startDate.setMinutes(0);

    return { startDate: startDate.getTime(), endDate: endDate.getTime() };
  };

  const userDevices = props?.user?.userDevices;

  const time = getTimeFilter();
  const selectedDeviceIndex =
    props?.user?.snn &&
    localStorage.getItem(props?.user?.snn + "setSimpleSelect")
      ? localStorage.getItem(props?.user?.snn + "setSimpleSelect")
      : 0;

  const queryData = {
    deviceSnn: userDevices?.[selectedDeviceIndex]?.serial,
    startTs: time.startDate,
    endTs: time.endDate,
  };

  if (props?.user && !props.eventsQueryData?.startTs) {
    setTimeout(() => {
      props.setEventsQuery(queryData);
      setSimpleSelect(selectedDeviceIndex);
    }, 500);
  }
  const [startTs, setStartTs] = React.useState(queryData.startTs);
  const [endTs, setEndTs] = React.useState(queryData.endTs);

  const { setToken } = useToken();
  const showDeviceSelect = userDevices && userDevices.length > 1;
  const [simpleSelect, setSimpleSelect] = React.useState(0);

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const logOutUser = () => {
    setToken(null);
  };
  const hideSidebar = () => {
    if (props.handleDrawerToggle) props.handleDrawerToggle();
  };
  const handleCloseProfile = () => {
    hideSidebar();
    setOpenProfile(null);
  };
  const classes = useStyles();
  const classes2 = useStyles2();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const getNewData = (event) => {
    hideSidebar();
    props.fetchEventsData(props.eventsQueryData);
  };

  const handleDateChange = (event, type) => {
    const dateChange = event.valueOf();
    let query = props.eventsQueryData;
    if (type === "end") {
      if (query.startTs >= dateChange) {
        titleAndTextAlert("End date can not be before start date.");
      } else {
        setEndTs(dateChange);
        query.endTs = dateChange;
        props.setEventsQuery(query);
      }
    } else {
      if (query.endTs <= dateChange) {
        titleAndTextAlert("Start date can not be after end date.");
      } else {
        setStartTs(dateChange);
        query.startTs = dateChange;
        props.setEventsQuery(query);
      }
    }
  };

  const handleSimple = (event) => {
    hideSidebar();
    const index = event.target.value;
    let query = props.eventsQueryData;
    query.deviceSnn = userDevices[index].serial;
    props.setEventsQuery(query);
    localStorage.setItem(props?.user?.snn + "setSimpleSelect", index);
    setSimpleSelect(index);
    getNewData();
  };

  const createSelectOptions = (options) => {
    return options.map((option, i) => {
      const name = option.name ? option.name : option.serial;
      const deviceType = option.serial.includes("GEYSER")
        ? "Geyser"
        : option.serial.includes("FIRE")
        ? "Fire"
        : "Secdev";

      const label = name + " (" + deviceType + ")";
      return (
        <MenuItem
          classes={{
            root: classes2.selectMenuItem,
            selected: classes2.selectMenuItemSelected,
          }}
          value={i}
          key={i}
        >
          {label}
        </MenuItem>
      );
    });
  };

  return (
    <div className={wrapper}>
      {showDeviceSelect ? (
        <FormControl
          style={{ paddingRight: "10px" }}
          className={
            classes.top +
            " " +
            classes.search +
            " " +
            "selectFormUnderlineColor"
          }
        >
          <label className="date-lable MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink">
            Device
          </label>
          <Select
            value={simpleSelect}
            onChange={handleSimple}
            id="device-selector"
            inputProps={{
              id: "device-select",
            }}
            MenuProps={{
              id: "device-select",
            }}
            classes={{
              select: classes2.select + " select-color",
            }}
            style={{ marginTop: "-0.9px" }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes2.selectMenuItem,
              }}
            >
              Choose Device
            </MenuItem>
            {userDevices ? createSelectOptions(userDevices) : null}
          </Select>
        </FormControl>
      ) : (
        ""
      )}
      <FormControl style={{ paddingRight: "10px" }} className={classes.search}>
        <label className="date-lable MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink">
          Start Date
        </label>
        <Datetime
          rtlActive={rtlActive}
          value={new Date(startTs)}
          format="hh:mm DD.MM.YYYY A"
          inputProps={{
            placeholder: "Start time and date",
          }}
          onChange={(e) => handleDateChange(e, "start")}
          formControlProps={{
            className: classes.top + " " + classes.search,
          }}
        />
      </FormControl>
      <FormControl className={classes.top + " " + classes.search}>
        <label className="date-lable MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink">
          End Date
        </label>
        <Datetime
          value={new Date(endTs)}
          format="hh:mm DD.MM.YYYY A"
          rtlActive={rtlActive}
          inputProps={{
            placeholder: "End time and date",
          }}
          onChange={(e) => handleDateChange(e, "end")}
          formControlProps={{
            className: classes.top + " " + classes.search,
          }}
        />
      </FormControl>
      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
        onClick={getNewData}
      >
        {props.loading ? (
          <i className="fas fa-spinner fa-pulse"></i>
        ) : (
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        )}
      </Button>
      <NavLink
        to="/admin/dashboard"
        className={classes.itemLink + " " + classes.userCollapseLinks}
      >
        <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
          onClick={hideSidebar}
        >
          <Dashboard
            style={{ color: "rgb(85,85,85)" }}
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "لوحة القيادة" : "Dashboard"}
            </span>
          </Hidden>
        </Button>
      </NavLink>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <NavLink
                      to="/admin/user-page"
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <MenuItem
                        to="/admin/user-page"
                        onClick={handleCloseProfile}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "Profile"}
                      </MenuItem>
                    </NavLink>
                    <Divider light />
                    <MenuItem onClick={logOutUser} className={dropdownItem}>
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {alert}
    </div>
  );
}

HeaderLinks.propTypes = {
  user: PropTypes.object,
  eventsQueryData: PropTypes.object,
  setEventsQuery: PropTypes.func,
  fetchEventsData: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
  rtlActive: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    eventsQueryData: state.eventsReducer.queryData,
    loading: state.eventsReducer.loading,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, { setEventsQuery, fetchEventsData })(
  HeaderLinks
);
