import gql from 'graphql-tag';

export const DEVICE_EVENTS = gql`
  query currentFaults($filter: EsEventFilter) {
    eventsCollection(
      limit: 10000
      filter: $filter
    ) {
      totalCount
      esTotalCount
      esItems {
        attribute: _at_attribute
        start_ts: _at_start_ts
        end_ts: _at_end_ts
      }
    }
  }
`;

export const DEVICE_REPORT_EVENTS_A = gql`
  query vegaReport($serial: String!) {
    device(serial: $serial) {
      serial
      active {
        snn
        created_ts
        # _vega {
        #   geyserHistory(since: $since, until: $until)
        # }
        shadowReference {
          state {
            reported
          }
        }
      }
    }
  }
`;
export const DEVICE_REPORT_EVENTS = gql`
  query vegaReport($serial: String!, $since: Float!, $until: Float!) {
    device(serial: $serial) {
      serial
      active {
        snn
        created_ts
        _vega {
          geyserHistory(since: $since, until: $until)
        }
        shadowReference {
          state {
            reported
          }
        }
      }
    }
  }
`;
export const SECDEV_DEVICE_REPORT_EVENTS = gql`
  query vegaReport($serial: String!, $since: Float!, $until: Float!) {
    device(serial: $serial) {
      serial
      meta
      profile
      active {
        meta
        snn
        created_ts
        _vega {
          secdevHistory(since: $since, until: $until)
        }
        shadowReference {
          state {
            reported
          }
        }
      }
    }
  }
`;
