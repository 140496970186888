// const server_host = 'http://localhost:8080';
const server_host_dev = "https://dev.sensornetworks.co.za";
const server_host_qa = "https://qa.sensornetworks.co.za";
const server_host_prod = "https://production.sensornetworks.co.za";

const stage = process.env.REACT_APP_STAGE;
const server_host =
  stage === "prod"
    ? server_host_prod
    : stage === "qa"
    ? server_host_qa
    : server_host_dev;

const dev = {
  production: false,
  hmr: false,
  googleMapsApiKey: "AIzaSyCrF3pduoZ5dad98tjOukhp83XueLO10xk",
  service_path: "/ngsw-worker.js",
  api: server_host + "/sensordesk/api",
  base: server_host,
  newFeatures: true,
  manifest: "https://sn-cms-qa.storage.googleapis.com/UI/sd2/manifest.json",
  testDevice: "10000576GEYSER",
  testEmail: "",
  testPass: "",
  clientId: "sensornetworks-ios-app",
  clientSecret: "So27AF7iu4v2gGFe3Ap2",
  socket: {
    websocket:
      server_host + "/websocket-server/resources/client/websocket-1.0.js",
    websocketServerUrl: server_host + "/websocket-server/",
    oauthAuthorizeUrl: server_host + "/credentials-authority/oauth/authorize",
    oauthTokenUrl: server_host + "/credentials-authority/oauth/token",
    logoutUrl: "/auth/login-page",
  },
};
const qa = {
  production: true,
  hmr: false,
  googleMapsApiKey: "AIzaSyCrF3pduoZ5dad98tjOukhp83XueLO10xk",
  api: server_host + "/sensordesk/api",
  service_path: "/UI/sd2/ngsw-worker.js",
  base: server_host,
  newFeatures: true,
  manifest: "https://sn-cms-qa.storage.googleapis.com/UI/sd2/manifest.json",
  testDevice: "10000576GEYSER",
  testEmail: "",
  testPass: "",
  clientId: "sensornetworks-ios-app",
  clientSecret: "So27AF7iu4v2gGFe3Ap2",
  socket: {
    websocket:
      server_host + "/websocket-server/resources/client/websocket-1.0.js",
    websocketServerUrl: server_host + "/websocket-server/",
    oauthAuthorizeUrl: server_host + "/credentials-authority/oauth/authorize",
    oauthTokenUrl: server_host + "/credentials-authority/oauth/token",
    logoutUrl: "/auth/login-page",
  },
};

const prod = {
  production: true,
  hmr: false,
  googleMapsApiKey: "AIzaSyCrF3pduoZ5dad98tjOukhp83XueLO10xk",
  api: server_host + "/sensordesk/api",
  service_path: "/UI/sd2/ngsw-worker.js",
  base: server_host,
  newFeatures: false,
  manifest:
    "https://sn-cms-production.storage.googleapis.com/UI/sd2/manifest.json",
  testDevice: "10000390GEYSER",
  testEmail: "test@henryk.co.za",
  testPass: "Cimputer0#",
  clientId: "sensornetworks-ios-app",
  clientSecret: "So27AF7iu4v2gGFe3Ap2",
  socket: {
    websocket:
      server_host + "/websocket-server/resources/client/websocket-1.0.js",
    websocketServerUrl: server_host + "/websocket-server/",
    oauthAuthorizeUrl: server_host + "/credentials-authority/oauth/authorize",
    oauthTokenUrl: server_host + "/credentials-authority/oauth/token",
    logoutUrl: "/auth/login-page",
  },
};

const fireBase = {
  firebase: {
    apiKey: "AIzaSyBbs5zlwkNIp1P4OjRHj7E21xzsQUY323E",
    authDomain: "sensor-smart-tracker-dev.firebaseapp.com",
    databaseURL: "https://sensor-smart-tracker-dev.firebaseio.com",
    storageBucket: "sensor-smart-tracker-dev.appspot.com",
    projectId: "sensor-smart-tracker-dev",
    messagingSenderId: "990866494678",
  },
};

const config = stage === "prod" ? prod : stage === "qa" ? qa : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
  ...fireBase,
};
