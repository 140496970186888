const initialState = {
  user: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { user: formatUserData(action.payload) };
    default:
      return state;
  }
};
const formatUserData = (userData) => {
  const friendDevicesName = userData?.friendsActiveDevicesCollection?.esItems.map(
    (device) =>
      (device = {
        serial: device.serial,
        name: device?.active?.serviceStub?.name,
      })
  );

  const ownedDevicesName = userData?.ownedActiveDevicesCollection?.esItems.map(
    (device) =>
      (device = {
        serial: device.serial,
        name: device?.active?.serviceStub?.name,
      })
  );

  const mobileFrontPageDevicesName = userData?.mobileFrontPage
    .map(
      (item) =>
        (item = { serial: item?.device?.serial, name: item?.service?.name })
    )
    .filter((item) => item?.serial);

  const myDevices = [
    ...ownedDevicesName,
    ...friendDevicesName,
    ...mobileFrontPageDevicesName,
  ]
    .filter((v, i, a) => a.findIndex((val) => val.serial === v.serial) === i)
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  return { ...userData, userDevices: myDevices };
};
export default userReducer;
