const initialState = {
  zoneData: null,
  violatedZones: null,
};

const zoneDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ZONEDATA":
      return { ...state, zoneData: action.payload };
    case "SET_VIOLATED_ZONES":
      return { ...state, violatedZones: action.payload };
    default:
      return state;
  }
};

export default zoneDataReducer;
