import environment from "../../config.js";
export class SensorTrackerService {
  
  token: any;
  opts = {
    clientId: environment.clientId,
    oauthAuthorizeUrl:
      "https://qa.sensornetworks.co.za/credentials-authority/oauth/authorize"
  };
  trampolineUrl: string = '';
  redirectUrl: string = '';

  public IFrameAuthorizer(opts: any, hash: any) {
    if (hash === void 0) {
      hash = window.location.hash;
    }
    let _this = this;
    _this.opts = opts;
    if (!opts || !opts.oauthAuthorizeUrl) {
      throw new Error("oauthAuthorizeUrl is mandatory");
    }
    if (!opts.clientId) {
      opts.clientId = environment.clientId;
    }
    _this.redirectUrl = window.location.href.replace(/#.*/, "");
    _this.trampolineUrl = _this.redirectUrl.replace(
      /\/?([^/]*.html)?$/,
      "/trampoline.html"
    );
    let token = _this.parseHash(hash);
    if (!token) {
        _this.redirectToOAuthAuthorization();
        return _this;
    }
    _this.setToken(token);
    return _this;
  }

  start() {
    this.schedule();
  }
  schedule() {
    let _this = this;
    let timeout = Math.max(0, this.token.exp * 1000 - Date.now() - 120000);
    console.log(
      "Authorizer: scheduling token renewal in " +
        Math.round(timeout / 1000) +
        " seconds"
    );
    setTimeout(function() {
      return _this.renew();
    }, timeout);
  }
  renew() {
    let _this = this;
    console.log("Authorizer: renewing token");
    this.obtainNewTokenInIframe()
      .then(function(token) {
        _this.setToken(token);
        _this.schedule();
      })
      .catch(function(e) {
        console.log("Could not reauthorize token.");
        _this.redirectToOAuthAuthorization();
      });
  }

  setToken(token: unknown) {
    console.log("Authorizer: new token ", token);
    if(token && token['snn']) localStorage.setItem('uid', token['snn'])
    this.token = token;
  }

  constructOAuthAuthorizationUrl(opts: { clientId: any; oauthAuthorizeUrl: any; }, redirectUrl: string | number | boolean ) {
    let url = opts.oauthAuthorizeUrl;
    url += "?response_type=token";
    url += "&client_id=" + opts.clientId;
    url += "&redirect_uri=" + encodeURIComponent(redirectUrl);

    return url;
  }

  redirectToOAuthAuthorization() {
    const url = this.constructOAuthAuthorizationUrl(
      this.opts,
      this.redirectUrl
    );
    console.log("Authorizer: redirecting to " + url);
    window.location.replace(url);
  }

  parseHash(hash: string) {
    function parseJwtToken(jwtToken: string) {
      jwtToken = jwtToken.replace(/^jwt=/, "");
      let parts = jwtToken.split(".");
      if (!parts || parts.length !== 3) {
        throw new Error("Invalid JWT Token");
      }
      let b = Buffer.from(parts[1], "base64");
      let o = JSON.parse(b.toString("utf-8"));
      o.jwtToken = "jwt=" + jwtToken;
      return o;
    }

    let expiresIn = parseInt(hash.replace(/.*expires_in=(\d+).*/, "$1"), 10);

    let accessToken = decodeURIComponent(
      hash.replace(/.*access_token=([^&]+).*/, "$1")
    );
    if (!expiresIn || !accessToken) {
      return null;
    }
    return parseJwtToken(accessToken);
  }

  obtainNewTokenInIframe() {
    let _this = this;
    return new Promise(function(resolve, reject) {
      let timeout: NodeJS.Timeout;
      let cleanup = function() {
        window.removeEventListener("message", processMessage);
        document.body.removeChild(iframe);
        clearTimeout(timeout);
      };
      let processMessage = function(event: { origin: string; data: string; }) {
        console.log("Authorizer: message received ", event);
        if (event.origin !== document.location.origin) {
          return;
        }
        if (typeof event.data !== "string") {
          return;
        }
        if (event.data.startsWith("$$authorization:")) {
          let h = event.data.substr("$$authorization:".length);
          let token = _this.parseHash(h);
          console.log("teee");
          cleanup();
          if (!token) {
            reject(new Error("LOGGED-OUT"));
            return;
          }
          resolve(token);
        }
      };
      timeout = setTimeout(function() {
        cleanup();
        reject(new Error("TIMEOUT"));
      }, 10000);
      let iframe: any = document.createElement("IFRAME");
      iframe.style.width = "1px";
      iframe.style.height = "1px";
      iframe.style.position = "absolute";

      let src = _this.constructOAuthAuthorizationUrl(
        _this.opts,
        _this.trampolineUrl
      );

      iframe["src"] = src;
      document.body.appendChild(iframe);
      console.log("Authorizer: iframe created with source " + src, iframe);
      window.addEventListener("message", processMessage);
    });
  }
}
