import { getUser } from "../../services/user.requests";
import { ACCOUNT_QUERY } from "../../queries/users";
import graphQlClient from "../../graphql/client";

export function fetchUserDataRest(uid) {
  return function (dispatch) {
    return getUser(uid).then((userData) => {
      if (userData.uid) {
        dispatch(setUser(userData));
      }
    });
  };
}
export function fetchUserData(uid) {
  return function (dispatch) {
    graphQlClient
      .query({
        query: ACCOUNT_QUERY,
        variables: { snn: uid },
      })
      .then((userData) => {
        if (userData.data) {
          const user = userData.data.account;
          const image = user?.meta
            ? user?.meta["sensordesk:info"]?.image
            : null;

          const queries = userData.data.queries.mobileFrontPageServices;
          const filteredData = { ...user, image, mobileFrontPage: queries };
          dispatch(setUser(filteredData));
        }
      });
  };
}

export const setUser = (content) => ({
  type: "SET_USER",
  payload: content,
});
