import userReducer from "./userReducer";
import eventsReducer from "./eventsReducer";
import priceReducer from "./priceReducer";
import floorPlanReducer from "./floorPlanReducer";
import zoneReducer from "./zoneReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  eventsReducer,
  userReducer,
  priceReducer,
  floorPlanReducer,
  zoneReducer,
});

export default rootReducer;
